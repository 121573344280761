import {
	ERRORS,
	CLEAR_RESPONSE,
	PIX_CLIENT_REQUEST,
	PIX_CLIENT_FAILURE,
	PIX_CLIENT_SUCCESS,
	PIX_ALL_CLIENT_REQUEST,
	PIX_ALL_CLIENT_FAILURE,
	PIX_ALL_CLIENT_SUCCESS,
	PIX_TRANSACTION_REQUEST,
	PIX_TRANSACTION_FAILURE,
	PIX_TRANSACTION_SUCCESS,
	PIX_CLIENT_TRANSACTION_REQUEST,
	PIX_CLIENT_TRANSACTION_FAILURE,
	PIX_CLIENT_TRANSACTION_SUCCESS,
	PIX_CLIENT_REJECT_TRANSACTION_FAILURE,
	PIX_CLIENT_REJECT_TRANSACTION_SUCCESS,
	PIX_CLIENT_REJECT_TRANSACTION_REQUEST,
	PIX_CLIENT_APPROVE_TRANSACTION_FAILURE,
	PIX_CLIENT_APPROVE_TRANSACTION_SUCCESS,
	PIX_CLIENT_APPROVE_TRANSACTION_REQUEST,
	PIX_CLIENT_FEES_TRANSACTION_REQUEST,
	PIX_CLIENT_FEES_TRANSACTION_FAILURE,
	PIX_CLIENT_FEES_TRANSACTION_SUCCESS,
	PIX_CLIENT_VERIFICATION_CODE_REQUEST,
	PIX_CLIENT_VERIFICATION_CODE_FAILURE,
	PIX_CLIENT_VERIFICATION_CODE_SUCCESS,
	CLEAR_PIX_CLIENT_FEES_TRANSACTION,
	PIX_SETTING_REQUEST,
	PIX_SETTING_FAILURE,
	PIX_SETTING_SUCCESS,
	UPDATE_PIX_SETTING_REQUEST,
	UPDATE_PIX_SETTING_FAILURE,
	UPDATE_PIX_SETTING_SUCCESS,
	REMOVE_PIX_CLIENT_REQUEST,
	REMOVE_PIX_CLIENT_SUCCESS,
	REMOVE_PIX_CLIENT_FAILURE,
	GET_PIX_CLIENT_BALANCE_REQUEST,
	GET_PIX_CLIENT_BALANCE_FAILURE,
	GET_PIX_CLIENT_BALANCE_SUCCESS,
	GET_PIX_EXCHANGE_RATE_REQUEST,
	GET_PIX_EXCHANGE_RATE_FAILURE,
	GET_PIX_EXCHANGE_RATE_SUCCESS,
} from "./actionTypes";

const initialState = {
	errors: {},
	response: {},
	isLoading: false,
	clients: [],
	client: {},
	clientBalance: {},
	transactions: [],
	clientTransactions: [],
	pixSetting: {},
	exchangeRate: {},
	clientTransactionFeeDetail: {},
	isWithdrawalTransactionSuccess: false,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case ERRORS:
			return {
				...state,
				errors: action.payload,
				isLoading: false,
			};

		case CLEAR_RESPONSE:
			return {
				...state,
				errors: {},
				response: {},
			};

		case PIX_CLIENT_SUCCESS:
			return {
				...state,
				client: action.payload.data,
				isLoading: false,
				errors: {},
			};

		case PIX_CLIENT_FAILURE:
			return {
				...state,
				client: {},
				isLoading: false,
				errors: {},
			};

		case PIX_TRANSACTION_SUCCESS:
			return {
				...state,
				transactions: action.payload.data,
				isLoading: false,
				errors: {},
			};
		case PIX_TRANSACTION_FAILURE:
			return {
				...state,
				transactions: [],
				isLoading: false,
				errors: {},
			};

		case PIX_CLIENT_REQUEST:
		case PIX_ALL_CLIENT_REQUEST:
		case PIX_TRANSACTION_REQUEST:
		case PIX_CLIENT_TRANSACTION_REQUEST:
		case GET_PIX_CLIENT_BALANCE_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case PIX_CLIENT_TRANSACTION_SUCCESS:
			return {
				...state,
				clientTransactions: action.payload.data,
				isLoading: false,
				errors: {},
			};
		case PIX_CLIENT_TRANSACTION_FAILURE:
			return {
				...state,
				clientTransactions: [],
				isLoading: false,
				errors: {},
			};

		case PIX_CLIENT_APPROVE_TRANSACTION_REQUEST:
		case PIX_CLIENT_REJECT_TRANSACTION_REQUEST:
			return {
				...state,
				isLoading: true,
			};

		case PIX_CLIENT_APPROVE_TRANSACTION_SUCCESS:
			return {
				...state,
				response: action.payload,
				isLoading: false,
				isWithdrawalTransactionSuccess: true,
				errors: {},
			};
		case PIX_CLIENT_REJECT_TRANSACTION_SUCCESS:
		case UPDATE_PIX_SETTING_SUCCESS:
		case REMOVE_PIX_CLIENT_SUCCESS:
			return {
				...state,
				response: action.payload,
				isLoading: false,
				errors: {},
			};

		case PIX_CLIENT_APPROVE_TRANSACTION_FAILURE:
			return {
				...state,
				response: action.payload,
				errors: action.payload?.error,
				isLoading: false,
				isWithdrawalTransactionSuccess: false,
			};
		case PIX_CLIENT_REJECT_TRANSACTION_FAILURE:
		case UPDATE_PIX_SETTING_FAILURE:
		case REMOVE_PIX_CLIENT_FAILURE:
			return {
				...state,
				response: action.payload,
				errors: action.payload?.error,
				isLoading: false,
			};

		case PIX_SETTING_SUCCESS:
			return {
				...state,
				pixSetting: action.payload.data,
			};
		case PIX_SETTING_FAILURE:
			return {
				...state,
				pixSetting: {},
			};

		case PIX_ALL_CLIENT_SUCCESS:
			return {
				...state,
				clients: action.payload.data,
				isLoading: false,
				errors: {},
			};
		case PIX_ALL_CLIENT_FAILURE:
			return {
				...state,
				clients: [],
				isLoading: false,
				errors: {},
			};

		case PIX_CLIENT_FEES_TRANSACTION_SUCCESS:
			return {
				...state,
				clientTransactionFeeDetail: action.payload.data,
				isLoading: false,
				response: {},
			};

		case PIX_CLIENT_FEES_TRANSACTION_REQUEST:
			return {
				...state,
				isLoading: true,
			};

		case PIX_CLIENT_FEES_TRANSACTION_FAILURE:
			return {
				...state,
				isLoading: false,
				clientTransactionFeeDetail: {},
				response: action.payload,
			};

		case CLEAR_PIX_CLIENT_FEES_TRANSACTION:
			return {
				...state,
				clientTransactionFeeDetail: {},
				response: {},
				errors: {},
				verificationCodeRequestResponse: {},
				isWithdrawalTransactionSuccess: false,
			};

		case PIX_CLIENT_VERIFICATION_CODE_REQUEST:
			return {
				...state,
				isLoading: true,
			};

		case PIX_CLIENT_VERIFICATION_CODE_SUCCESS:
			return {
				...state,
				response: action.payload,
				verificationCodeRequestResponse: action.payload.data,
				isLoading: false,
			};

		case PIX_CLIENT_VERIFICATION_CODE_FAILURE:
			return {
				...state,
				isLoading: false,
				verificationCodeRequestResponse: {},
				response: action.payload,
			};

		case GET_PIX_CLIENT_BALANCE_SUCCESS:
			return {
				...state,
				isLoading: false,
				clientBalance: action.payload.data,
			};

		case GET_PIX_CLIENT_BALANCE_FAILURE:
			return {
				...state,
				isLoading: false,
				clientBalance: {},
			};

		case GET_PIX_EXCHANGE_RATE_SUCCESS:
			return {
				...state,
				isLoading: false,
				exchangeRate: action.payload.data,
			};

		case GET_PIX_EXCHANGE_RATE_FAILURE:
			return {
				...state,
				isLoading: false,
				exchangeRate: {},
			};

		default:
			return state;
	}
};

export default reducer;
