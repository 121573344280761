export const ERRORS = "ERRORS";
export const CLEAR_RESPONSE = "CLEAR_RESPONSE";

export const CREATE_BANNER_REQUEST = "CREATE_BANNER_REQUEST";
export const CREATE_BANNER_SUCCESS = "CREATE_BANNER_SUCCESS";
export const CREATE_BANNER_FAILURE = "CREATE_BANNER_FAILURE";

export const UPDATE_BANNER_REQUEST = "UPDATE_BANNER_REQUEST";
export const UPDATE_BANNER_SUCCESS = "UPDATE_BANNER_SUCCESS";
export const UPDATE_BANNER_FAILURE = "UPDATE_BANNER_FAILURE";

export const REMOVE_BANNER_REQUEST = "REMOVE_BANNER_REQUEST";
export const REMOVE_BANNER_SUCCESS = "REMOVE_BANNER_SUCCESS";
export const REMOVE_BANNER_FAILURE = "REMOVE_BANNER_FAILURE";

export const TOGGLE_BANNER_SUCCESS = "TOGGLE_BANNER_SUCCESS";
export const TOGGLE_BANNER_REQUEST = "TOGGLE_BANNER_REQUEST";
export const TOGGLE_BANNER_FAILURE = "TOGGLE_BANNER_FAILURE";
