import {
	ERRORS,
	CLEAR_RESPONSE,
	CREATE_TKX_ENDPOINT_REQUEST,
	UPDATE_TKX_ENDPOINT_REQUEST,
	REMOVE_TKX_ENDPOINT_REQUEST,
	TOGGLE_TKX_ENDPOINT_REQUEST,
	CREATE_TKX_HOST_REQUEST,
	UPDATE_TKX_HOST_REQUEST,
	REMOVE_TKX_HOST_REQUEST,
	TOGGLE_TKX_HOST_REQUEST,
	CLEAR_ERRORS,
} from "./actionTypes";

export const clearResponse = () => {
	return {
		type: CLEAR_RESPONSE,
		payload: {},
	};
};

export const clearErrors = () => {
	return {
		type: CLEAR_ERRORS,
		payload: {},
	};
};

export const create = (data) => {
	return {
		type: CREATE_TKX_ENDPOINT_REQUEST,
		payload: { data },
	};
};

export const update = (data) => {
	return {
		type: UPDATE_TKX_ENDPOINT_REQUEST,
		payload: { data },
	};
};

export const remove = (id) => {
	return {
		type: REMOVE_TKX_ENDPOINT_REQUEST,
		payload: { id },
	};
};

export const toggle = (id) => {
	return {
		type: TOGGLE_TKX_ENDPOINT_REQUEST,
		payload: { id },
	};
};

export const createHost = (data) => {
	return {
		type: CREATE_TKX_HOST_REQUEST,
		payload: { data },
	};
};

export const updateHost = (data) => {
	return {
		type: UPDATE_TKX_HOST_REQUEST,
		payload: { data },
	};
};

export const removeHost = (id) => {
	return {
		type: REMOVE_TKX_HOST_REQUEST,
		payload: { id },
	};
};

export const toggleHost = (id) => {
	return {
		type: TOGGLE_TKX_HOST_REQUEST,
		payload: { id },
	};
};
