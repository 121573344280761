import React, { useEffect, useState } from "react";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Helmet } from "react-helmet";
import {
	Card,
	CardBody,
	Col,
	Row,
	Container,
	CardHeader,
	CardTitle,
	Table,
	Input,
	Label,
	Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import isEmpty from "../../utils/isEmpty";
import { useSelector, useDispatch } from "react-redux";
import {
	getTKXPAYSetting,
	updateTKXPAYSetting,
	clearResponse,
} from "../../store/admin/actions";
import { toast } from "react-toastify";
import HasAnyPermission from "../../common/Permission";
import hasPermission from "../../common/HasPermission";
import uniqid from "uniqid";

const TKXPAYSetting = (props) => {
	const dispatch = useDispatch();
	var [transactionEmailBCC, setEmailBcc] = useState([
		{ key: uniqid(), value: "" },
	]);

	const { TKXPAYSetting, auth, response, isLoading } = useSelector(
		(state) => ({
			response: state.admin.response,
			TKXPAYSetting: state.admin.TKXPAYSetting,
			isLoading: state.admin.isLoading,
			auth: state.auth,
		})
	);

	useEffect(() => {
		dispatch(getTKXPAYSetting());
	}, []);

	useEffect(() => {
		if (response && response.code === "200") {
			toast.success(response.msg, {
				onOpen: () => {
					dispatch(clearResponse());
					dispatch(getTKXPAYSetting());
				},
			});
		} else if (response && response?.msg) {
			toast.error(response.msg, {
				onOpen: () => {
					dispatch(clearResponse());
				},
			});
		}
	}, [dispatch, response]);

	const validation = useFormik({
		enableReinitialize: true,
		initialValues: {
			url: TKXPAYSetting.url ?? "",
		},
		validationSchema: Yup.object({}),
		onSubmit: (values) => {
			if (!isEmpty(values)) {
				Swal.fire({
					title: "Are you sure?",
					text: "Do you really want to update?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "Confirm",
				}).then((result) => {
					if (result.value) {
						dispatch(updateTKXPAYSetting({ ...values }));
					}
				});
			}
		},
	});

	return (
		<React.Fragment>
			<div className="page-content">
				<Helmet>
					<title>TKXPAY LIVE Setting | TOKEX</title>
				</Helmet>
				<Container fluid>
					{/* Render Breadcrumbs */}
					<Breadcrumbs
						title="TKXPAY LIVE URL"
						breadcrumbItem="Setting"
					/>

					<Row>
						<Card className="border border-default">
							<CardHeader className="align-items-center d-flex bg-transparent border-default">
								<CardTitle className="mb-0 flex-grow-1">
									<h5 className="my-0 text-primary">
										<i className="mdi mdi-bullseye-arrow me-3"></i>
										Setting
									</h5>
								</CardTitle>
							</CardHeader>
							<CardBody>
								<Row className="mt-3">
									<Col sm={3}>
										<h5>TKXPAY LIVE URL</h5>
									</Col>
									<Col>
										<div className="mb-1">
											<fieldset
												disabled={
													!hasPermission(
														["update pix setting"],
														auth.currentUserRolePermissions
													)
												}
											>
												<Form
													onSubmit={(e) => {
														e.preventDefault();
														validation.handleSubmit();
														return false;
													}}
													className="row gx-3 gy-2 align-items-center"
												>
													<Col sm={6}>
														<Input
															type="text"
															name="url"
															className="form-control"
															onChange={
																validation.handleChange
															}
															onBlur={
																validation.handleBlur
															}
															value={
																validation
																	.values
																	.url || ""
															}
														/>
													</Col>
													<div className="col-auto">
														<button
															type="submit"
															className="btn btn-primary"
														>
															Update
														</button>
													</div>
												</Form>
											</fieldset>
										</div>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default TKXPAYSetting;
