import React, { useEffect, useState, useRef, useMemo } from "react";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Helmet } from "react-helmet";
import {
	Card,
	CardBody,
	Col,
	Nav,
	NavItem,
	NavLink,
	Row,
	TabContent,
	TabPane,
	Modal,
	ModalHeader,
	ModalBody,
	Container,
	Form,
	FormFeedback,
	Label,
	Input,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
//redux
import { useSelector, useDispatch } from "react-redux";
import HasAnyPermission from "../../common/Permission";
import hasPermission from "../../common/HasPermission";
import ReactDataTable from "../../common/ReactDataTable";
import { pixTransactionStatus } from "../../common/List";
import { apiUrl } from "../../config";
import { toast } from "react-toastify";
import isEmpty from "../../utils/isEmpty";
import formatAddressType from "../../utils/formatAddressType";
import {
	approveTransaction,
	notifyUserTransaction,
	getTotalFeesTransaction,
	transactionVerificationRequest,
	clearResponse,
	clearPixClientFeesTranaction,
	getClientBalance,
} from "../../store/pix/actions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactTooltip from "react-tooltip";

import moment from "moment";

const Transaction = (props) => {
	const dispatch = useDispatch();
	const refreshTableData = useRef(null);
	const reactTooltipRefs = useRef([]);

	const {
		response,
		clientTransactionFeeDetail,
		verificationCodeRequestResponse,
		auth,
		isLoading,
		isWithdrawalTransactionSuccess,
		clientBalance,
	} = useSelector((state) => ({
		response: state.pix.response,
		isLoading: state.pix.isLoading,
		clientBalance: state.pix.clientBalance,
		clientTransactionFeeDetail: state.pix.clientTransactionFeeDetail,
		isWithdrawalTransactionSuccess:
			state.pix.isWithdrawalTransactionSuccess,
		verificationCodeRequestResponse:
			state.pix.verificationCodeRequestResponse,
		auth: state.auth,
	}));

	const [transactionDetails, setTransactionDetails] = useState({
		isOpenStatusModal: false,
		isOpenPayModal: false,
		details: {},
	});

	useEffect(() => {
		dispatch(getClientBalance({ symbol: "USDT" }));
	}, []);

	const useFormikOptionForNotify = {
		enableReinitialize: true,
		initialValues: {
			subject: "",
			message: "",
		},
		validationSchema: Yup.object({
			subject: Yup.string().required("Please enter subject."),
			message: Yup.string().required("Please enter message."),
		}),
		onSubmit: (values) => {
			dispatch(
				notifyUserTransaction({
					txId: transactionDetails.details.txId,
					...values,
				})
			);
		},
	};

	const validationNotifyForm = useFormik(useFormikOptionForNotify);

	useEffect(() => {
		if (response && response.code === "200") {
			refreshTableData.current();
			toast.success(response.msg, {
				onOpen: () => {
					dispatch(clearResponse());
				},
			});
			if (
				isWithdrawalTransactionSuccess &&
				transactionDetails.isOpenPayModal
			) {
				setTransactionDetails({
					...transactionDetails,
					isOpenPayModal: false,
				});

				dispatch(clearPixClientFeesTranaction());
			}
			if (validationNotifyForm) validationNotifyForm.resetForm();
			if (transactionDetails.isOpenStatusModal) {
				setTransactionDetails({
					...transactionDetails,
					isOpenStatusModal: false,
				});
				dispatch(clearPixClientFeesTranaction());
			}
		} else if (response && response?.msg) {
			refreshTableData.current();
			toast.error(response.msg, {
				onOpen: () => {
					dispatch(clearResponse());
				},
			});
		}
	}, [dispatch, response]);

	const url = props.userId
		? `${apiUrl}/payment_gateway/pix/transactions/pagination/${props.userId}`
		: `${apiUrl}/payment_gateway/pix/transactions/pagination`;

	const useFormikOptionForSend = {
		enableReinitialize: true,
		initialValues: {
			address: "",
		},
		validationSchema: Yup.object({
			address: Yup.string().required("Please select addresss."),
		}),
		onSubmit: (values) => {
			dispatch(
				transactionVerificationRequest(clientTransactionFeeDetail)
			);
		},
	};
	const validationVerification = useFormik({
		enableReinitialize: true,
		initialValues: {
			code: "",
		},
		validationSchema: Yup.object({
			code: Yup.string().required("Please enter code."),
		}),
		onSubmit: (values) => {
			dispatch(
				approveTransaction({
					...clientTransactionFeeDetail,
					...values,
					txId: transactionDetails.details.txId,
					verificationType:
						verificationCodeRequestResponse?.verificationType,
					[verificationCodeRequestResponse?.verificationType]:
						values.code,
				})
			);
		},
	});

	const validationSendForm = useFormik(useFormikOptionForSend);

	const handleTransactionAddressChange = (data) => {
		const transaction = transactionDetails.details;
		const transactionValue = transaction.data.value;
		dispatch(
			getTotalFeesTransaction({
				...data,
				exchange: transaction.exchange,
				value: transactionValue,
			})
		);
	};

	const notifyClientForTransaction = (data) => {
		setTransactionDetails({
			...transactionDetails,
			details: data,
			isOpenStatusModal: !transactionDetails.isOpenStatusModal,
		});
	};

	const openTransactionPayModal = (data) => {
		dispatch(clearPixClientFeesTranaction());
		validationVerification?.resetForm();
		validationSendForm?.resetForm();
		setTransactionDetails({
			...transactionDetails,
			isOpenPayModal: !transactionDetails.isOpenPayModal,
			details: data,
		});
	};

	const handleTooltipOpen = () => {
		toast.success("Copied to clipboard.");
	};

	const expandableComponent = (rowData, rowMeta) => {
		const data = rowData[rowData.length - 1];
		return formatAddressType(data?.userAddresses)?.map((item) => (
			<tr key={item.addressType} className="MuiTableRow-root">
				<td colSpan="1"> </td>
				<td colSpan="1">{`USDT-${item.addressType}`}</td>
				<td colSpan="6" style={{ wordBreak: "break-all" }}>
					{isEmpty(item.address) ? (
						"-"
					) : (
						<>
							<ReactTooltip
								id={item.address}
								type="info"
								effect="float"
								place="bottom"
								getContent={() => `Click to copy`}
							/>

							<CopyToClipboard
								text={item.address}
								data-tip
								data-for={item.address}
								style={{ cursor: "pointer" }}
								ref={reactTooltipRefs[item.address]}
								onCopy={() => {
									handleTooltipOpen();
									ReactTooltip.hide(
										reactTooltipRefs[item.address]
									);
								}}
							>
								<span style={{ cursor: "pointer" }}>
									{item.address}{" "}
									<i className="mdi mdi-content-copy"></i>
								</span>
							</CopyToClipboard>
						</>
					)}
				</td>
			</tr>
		));
	};

	const columns = () => [
		{
			label: "Client Name",
			name: "name_client",
			options: {
				filter: false,
				sort: false,
			},
		},

		{
			label: "Client Email",
			name: "email_client",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			label: "Client CPF",
			name: "cpf_client",
			options: {
				filter: false,
				sort: false,
			},
		},

		{
			label: "TransactioID",
			name: "txId",
			options: {
				filter: false,
				sort: false,
			},
		},

		{
			label: "Value (BRL)",
			name: "data",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (data) => (data?.value ? data.value : "-"),
			},
		},
		{
			label: "Exchange",
			name: "exchange",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (exchange) => exchange ?? "-",
			},
		},

		{
			label: "Status",
			name: "status",
			options: {
				filter: true,
				sort: false,
				download: false,
				customBodyRender: (rowData) => {
					let type = "success";
					if (rowData !== "ACCEPTED") type = "warning";
					return (
						<div
							className={
								"badge badge-soft-" + type + " font-size-12"
							}
						>
							{rowData}
						</div>
					);
				},
				// customFilterListOptions: {
				// 	render: (v) =>
				// 		v.map((l) => (l === "true" ? "Active" : "Inactive")),
				// },
				filterType: "custom",
				filterOptions: {
					names: [],
					display: (filterList, onChange, index, column) => {
						const optionValues = pixTransactionStatus;
						return (
							<div className="mb-4">
								<label
									htmlFor="custom-status-value-filter"
									className="form-label font-size-13 text-muted"
								></label>
								<select
									value={filterList[index][0] || ""}
									onChange={(event) => {
										filterList[index][0] =
											event.target.value;
										onChange(
											filterList[index],
											index,
											column
										);
									}}
									className="form-control"
									name="custom-status-value-filter"
								>
									<option disabled value="">
										Status
									</option>
									{optionValues.map((item) => (
										<option key={item} value={item}>
											{item}
										</option>
									))}
								</select>
							</div>
						);
					},
				},
			},
		},

		{
			label: "CreatedAt",
			name: "createdAt",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (createdAt) =>
					moment(createdAt).format("L LTS"),
			},
		},

		{
			label: "Actions",
			name: "action",
			options: {
				filter: false,
				sort: false,
				empty: true,
				download: false,
				display:
					hasPermission(
						[
							"update pix transactions",
							"approve pix transactions",
							"notify client pix transactions",
						],
						auth.currentUserRolePermissions
					) && auth.user.isTkxTradeUser,
				viewColumns:
					hasPermission(
						[
							"update pix transactions",
							"approve pix transactions",
							"notify client pix transactions",
						],
						auth.currentUserRolePermissions
					) && auth.user.isTkxTradeUser,
				customBodyRender: (action) => {
					return (
						<HasAnyPermission
							permission={[
								"update pix transactions",
								"approve pix transactions",
								"notify client pix transactions",
							]}
						>
							<div className="d-flex flex-wrap gap-2">
								{action.approved ? (
									<button
										disabled
										type="button"
										className="btn btn-success btn-rounded waves-effect waves-light"
									>
										Paid
									</button>
								) : action.approved === false &&
								  action.processed === true ? (
									<>
										<HasAnyPermission
											permission={[
												"approve pix transactions",
											]}
										>
											<button
												onClick={(e) => {
													e.preventDefault();
													openTransactionPayModal(
														action
													);
												}}
												disabled={
													!auth.user.isTkxTradeUser
												}
												type="button"
												className="btn btn-primary btn-rounded waves-effect waves-light"
											>
												Pay
											</button>
										</HasAnyPermission>
										<HasAnyPermission
											permission={[
												"notify client pix transactions",
											]}
										>
											<button
												onClick={() =>
													notifyClientForTransaction(
														action
													)
												}
												disabled={
													!auth.user.isTkxTradeUser
												}
												type="button"
												className="btn btn-danger btn-rounded waves-effect waves-light"
											>
												{/* <i className="mdi mdi-trash-can d-block font-size-16"></i>{" "} */}
												Notify &nbsp;
												<span className="badge bg-primary rounded-pill">
													{`${
														action.notify
															? action.notify
															: ""
													}`}
												</span>
											</button>
										</HasAnyPermission>
									</>
								) : (
									<div
										className={
											"badge badge-soft-warning font-size-12"
										}
									>
										{"--"}
									</div>
								)}
							</div>
						</HasAnyPermission>
					);
				},
			},
		},
		{
			name: "action",
			label: "action",
			options: {
				filter: false,
				display: "excluded",
				viewColumns: false,
				download: false,
			},
		},
	];

	// const value = parseFloat(563.0);
	// const exchange = parseFloat(5.63);
	// const processingFees = parseFloat(1);
	// let total =
	// 	parseFloat(value / exchange) -
	// 	parseFloat(value / exchange) * (processingFees / 100);
	const resultFormatter = (result) => {
		return result.docs.map((item) => {
			return {
				...item,
				action: item,
			};
		});
	};

	return (
		<React.Fragment>
			<Row>
				<Col lg="12">
					<Card>
						<CardBody>
							<Row>
								<Col xl="12">
									<div className="table-rep-plugin">
										<div className="table-responsive">
											<HasAnyPermission
												permission={[
													"view pix transactions",
												]}
											>
												<ReactDataTable
													url={url}
													columns={columns()}
													resultFormatter={
														resultFormatter
													}
													setRefresh={
														refreshTableData
													}
													renderExpandableRow={
														expandableComponent
													}
													// isUnexpandedRows={true}
													expandableRows={true}
													disableFilterIcon={false}
													disableSearchIcon={false}
													// origin={
													// 	<div className="row">
													// 		<div className="col-auto h4"></div>
													// 	</div>
													// }
													rowsPerPage={10}
												/>
											</HasAnyPermission>
										</div>
									</div>
								</Col>
							</Row>
						</CardBody>
					</Card>
				</Col>
			</Row>

			<Modal
				isOpen={transactionDetails.isOpenStatusModal}
				toggle={() =>
					setTransactionDetails({
						...transactionDetails,
						isOpenStatusModal:
							!transactionDetails.isOpenStatusModal,
					})
				}
				backdrop={"static"}
				size="md"
				centered={true}
			>
				<ModalHeader
					toggle={() =>
						setTransactionDetails({
							...transactionDetails,
							isOpenStatusModal:
								!transactionDetails.isOpenStatusModal,
						})
					}
					tag="h4"
				>
					{"Notify Client"}
				</ModalHeader>
				<ModalBody>
					<Form
						// form={true}
						// onSubmit={
						// 	validationNotifyForm.handleSubmit
						// }
						onSubmit={(e) => {
							e.preventDefault();
							validationNotifyForm.handleSubmit();
							return false;
						}}
					>
						<Row form={"true"}>
							<Col xs={12}>
								<div className="mb-3">
									<Label className="form-label">
										Subject
									</Label>
									<Input
										name="subject"
										type="text"
										onChange={
											validationNotifyForm.handleChange
										}
										onBlur={validationNotifyForm.handleBlur}
										value={
											validationNotifyForm.values
												.subject || ""
										}
										invalid={
											validationNotifyForm.touched
												.subject &&
											validationNotifyForm.errors.subject
												? true
												: false
										}
									/>
									{validationNotifyForm.touched.subject &&
									validationNotifyForm.errors.subject ? (
										<FormFeedback type="invalid">
											{
												validationNotifyForm.errors
													.subject
											}
										</FormFeedback>
									) : null}
								</div>
							</Col>

							<Col xs={12}>
								<div className="mb-3">
									<Label className="form-label">
										Message
									</Label>
									<Input
										name="message"
										type="textarea"
										rows="5"
										onChange={
											validationNotifyForm.handleChange
										}
										onBlur={validationNotifyForm.handleBlur}
										value={
											validationNotifyForm.values
												.message || ""
										}
										invalid={
											validationNotifyForm.touched
												.message &&
											validationNotifyForm.errors.message
												? true
												: false
										}
									/>
									{validationNotifyForm.touched.message &&
									validationNotifyForm.errors.message ? (
										<FormFeedback type="invalid">
											{
												validationNotifyForm.errors
													.message
											}
										</FormFeedback>
									) : null}
								</div>
							</Col>
						</Row>
						<Row>
							<Col>
								<div className="text-end mt-3">
									<button
										type="submit"
										className="btn btn-success save-user"
									>
										Send
									</button>
								</div>
							</Col>
						</Row>
					</Form>
				</ModalBody>
			</Modal>

			<Modal
				isOpen={transactionDetails.isOpenPayModal}
				backdrop={"static"}
				size="md"
				centered={true}
			>
				<div className="modal-header">
					<h5 className="modal-title mt-0" id="myModalLabel">
						{verificationCodeRequestResponse &&
						verificationCodeRequestResponse?.verificationType
							? "Security verification"
							: "Select address"}
					</h5>

					<h6 className="mt-0">
						{`Available balance: ${
							clientBalance.allCoinMap?.USDT?.normal_balance
								? clientBalance.allCoinMap.USDT.normal_balance
								: "0.00"
						} USDT`}
					</h6>
				</div>
				<ModalBody>
					{verificationCodeRequestResponse &&
					verificationCodeRequestResponse?.verificationType ? (
						<Form
							name="verificationForm"
							onSubmit={(e) => {
								e.preventDefault();
								validationVerification.handleSubmit();
								return false;
							}}
						>
							<Row form={"true"}>
								<Col xs={12}>
									<div className="mb-3">
										<Input
											name="code"
											type="text"
											placeholder={
												verificationCodeRequestResponse?.verificationType ===
												"emailValidCode"
													? "Enter email verification code"
													: "Enter mobile verification code"
											}
											onChange={(e) => {
												validationVerification.handleChange(
													e
												);
											}}
											onBlur={
												validationVerification.handleBlur
											}
											value={
												validationVerification.values
													.code || ""
											}
											invalid={
												validationVerification.touched
													.code &&
												validationVerification.errors
													.code
													? true
													: false
											}
										/>
										{validationVerification.touched.code &&
										validationVerification.errors.code ? (
											<FormFeedback type="invalid">
												{
													validationVerification
														.errors.code
												}
											</FormFeedback>
										) : null}
									</div>
								</Col>
								{verificationCodeRequestResponse?.googleCode && (
									<Col xs={12}>
										<div className="mb-3">
											<Input
												name="googleCode"
												type="text"
												placeholder="Enter google verification code"
												onChange={(e) => {
													validationVerification.handleChange(
														e
													);
												}}
												// onBlur={
												// 	validationVerification.handleBlur
												// }
												value={
													validationVerification
														.values.googleCode || ""
												}
												invalid={
													validationVerification
														.touched.googleCode &&
													validationVerification
														.errors.googleCode
														? true
														: false
												}
											/>
											{validationVerification.touched
												.googleCode &&
											validationVerification.errors
												.googleCode ? (
												<FormFeedback type="invalid">
													{
														validationVerification
															.errors.googleCode
													}
												</FormFeedback>
											) : null}
										</div>
									</Col>
								)}
							</Row>
							<Row>
								<Col>
									<div className="text-end mt-3">
										<div className="d-flex justify-content-end  gap-2">
											<button
												disabled={
													isLoading ? true : false
												}
												onClick={() => {
													dispatch(
														clearPixClientFeesTranaction()
													);
													setTransactionDetails({
														...transactionDetails,
														isOpenPayModal:
															!transactionDetails.isOpenPayModal,
													});
													validationVerification.resetForm();
													validationSendForm.resetForm();
												}}
												type="reset"
												className="btn btn-secondary"
											>
												Cancel
											</button>

											<button
												type="submit"
												className="btn btn-success save-user"
												disabled={
													isLoading ? true : false
												}
											>
												{isLoading ? (
													<>
														<i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
														{"Loading"}
													</>
												) : (
													"Confirm"
												)}
											</button>
										</div>
									</div>
								</Col>
							</Row>
						</Form>
					) : (
						<Form
							name="selectAddressTypeForm"
							onSubmit={(e) => {
								e.preventDefault();
								validationSendForm.handleSubmit();
								return false;
							}}
						>
							<Row form={"true"}>
								<Col xs={12}>
									<div className="mb-3">
										<Label className="form-label">
											Address
										</Label>
										<Input
											name="address"
											type="select"
											onChange={(e) => {
												const optionElement =
													e.target.childNodes[
														e.target.selectedIndex
													];
												const addressType =
													optionElement.getAttribute(
														"data-addresstype"
													);
												const addressMainType =
													optionElement.getAttribute(
														"data-addressmaintype"
													);
												validationSendForm.setFieldValue(
													"addressType",
													addressType
												);
												validationSendForm.setFieldValue(
													"addressMainType",
													addressMainType
												);

												handleTransactionAddressChange({
													addressType,
													addressMainType,
													address: e.target.value,
												});
												validationSendForm.handleChange(
													e
												);
											}}
											onBlur={
												validationSendForm.handleBlur
											}
											value={
												validationSendForm.values
													.address || ""
											}
											invalid={
												validationSendForm.touched
													.address &&
												validationSendForm.errors
													.address
													? true
													: false
											}
										>
											<option disabled value="">
												USDT Address
											</option>
											{transactionDetails.isOpenPayModal &&
												formatAddressType(
													transactionDetails?.details
														?.userAddresses
												)?.map((item) => (
													<option
														key={item.addressType}
														data-addresstype={
															item.addressType
														}
														data-addressmaintype={
															item.addressMainType
														}
														value={item.address}
													>
														{`USDT-${item.addressType} (${item.address})`}
													</option>
												))}
										</Input>
										{validationSendForm.touched.address &&
										validationSendForm.errors.address ? (
											<FormFeedback type="invalid">
												{
													validationSendForm.errors
														.address
												}
											</FormFeedback>
										) : null}
									</div>
								</Col>
								{clientTransactionFeeDetail?.total && (
									<>
										<Col xs={12}>
											<div className="mb-3">
												<Label className="form-label">
													Fees
												</Label>
												<Input
													name="fee"
													type="text"
													readOnly={true}
													value={
														clientTransactionFeeDetail?.fee
													}
												/>
											</div>
										</Col>
										<Col xs={12}>
											<div className="mb-3">
												<Label className="form-label">
													Total
												</Label>
												<Input
													name="total"
													type="text"
													readOnly={true}
													value={
														clientTransactionFeeDetail?.total
													}
												/>
											</div>
										</Col>
									</>
								)}
							</Row>
							<Row>
								<Col>
									<div className="text-end mt-3">
										<div className="d-flex justify-content-end  gap-2">
											<button
												disabled={
													isLoading ? true : false
												}
												onClick={() => {
													dispatch(
														clearPixClientFeesTranaction()
													);
													validationSendForm.resetForm();
													setTransactionDetails({
														...transactionDetails,
														isOpenPayModal:
															!transactionDetails.isOpenPayModal,
													});
												}}
												type="reset"
												className="btn btn-danger"
											>
												Cancel
											</button>

											<button
												type="submit"
												className="btn btn-success save-user"
												disabled={
													isLoading ? true : false
												}
											>
												{isLoading ? (
													<>
														<i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
														{"Loading"}
													</>
												) : (
													"Send"
												)}
											</button>
										</div>
									</div>
								</Col>
							</Row>
						</Form>
					)}
				</ModalBody>
			</Modal>
		</React.Fragment>
	);
};

export default Transaction;
