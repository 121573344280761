import axios from "axios";
import { apiUrl } from "../../config";

export const create = (data) =>
	axios
		.post(`${apiUrl}/admin/banner/create`, data, {
			headers: {
				"Content-Type": "multipart/form-data", //application/x-www-form-urlencoded
			},
		})
		.then((response) => response)
		.catch((err) => err.response);

export const update = (data, _id) =>
	axios
		.put(`${apiUrl}/admin/banner/update/${_id}`, data, {
			headers: {
				"Content-Type": "multipart/form-data", //application/x-www-form-urlencoded
			},
		})
		.then((response) => response)
		.catch((err) => err.response);

export const toggle = (id) =>
	axios
		.patch(`${apiUrl}/admin/banner/toggle-status/${id}`)
		.then((response) => response)
		.catch((err) => err.response);

export const remove = (id) =>
	axios
		.delete(`${apiUrl}/admin/banner/delete/${id}`)
		.then((response) => response)
		.catch((err) => err.response);
