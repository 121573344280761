export const ERRORS = "ERRORS";
export const CLEAR_RESPONSE = "CLEAR_RESPONSE";

export const CREATE_MOBILE_MENU_REQUEST = "CREATE_MOBILE_MENU_REQUEST";
export const CREATE_MOBILE_MENU_SUCCESS = "CREATE_MOBILE_MENU_SUCCESS";
export const CREATE_MOBILE_MENU_FAILURE = "CREATE_MOBILE_MENU_FAILURE";

export const UPDATE_MOBILE_MENU_REQUEST = "UPDATE_MOBILE_MENU_REQUEST";
export const UPDATE_MOBILE_MENU_SUCCESS = "UPDATE_MOBILE_MENU_SUCCESS";
export const UPDATE_MOBILE_MENU_FAILURE = "UPDATE_MOBILE_MENU_FAILURE";

export const REMOVE_MOBILE_MENU_REQUEST = "REMOVE_MOBILE_MENU_REQUEST";
export const REMOVE_MOBILE_MENU_SUCCESS = "REMOVE_MOBILE_MENU_SUCCESS";
export const REMOVE_MOBILE_MENU_FAILURE = "REMOVE_MOBILE_MENU_FAILURE";

export const TOGGLE_MOBILE_MENU_REQUEST = "TOGGLE_MOBILE_MENU_REQUEST";
export const TOGGLE_MOBILE_MENU_SUCCESS = "TOGGLE_MOBILE_MENU_SUCCESS";
export const TOGGLE_MOBILE_MENU_FAILURE = "TOGGLE_MOBILE_MENU_FAILURE";
