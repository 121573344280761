import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import CountUp from "react-countup";
import { isEmpty } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import {
	// totalClient,
	totalTransaction,
	clearResponse,
} from "../../store/dashboard/actions";
import moment from "moment";

const TotalPIXTransaction = (props) => {
	const dispatch = useDispatch();
	const { pixTranscations } = useSelector((state) => ({
		pixTranscations: state.dashboard.pixTranscations,
	}));
	const [details, handleDetail] = useState({
		transactions: {
			filters: {
				// type: "",
				userId: props?.userId ? props.userId : null,
				date: [null, null],
				// time: {
				// 	years: "",
				// 	months: "",
				// 	days: "",
				// 	hours: "",
				// 	last: "",
				// },
			},
			// search: "",
			// sort: "",
			// sortColumn: "",
		},
	});

	useEffect(() => {
		const filters = setDateFilter(details.transactions.filters);
		dispatch(
			totalTransaction({
				...details.transactions,
				filters: JSON.stringify(filters),
			})
		);
	}, [details.transactions]);

	const onChangeFilter = (event) => {
		handleDetail({
			...details,
			transactions: {
				...details.transactions,
				filters: {
					...details.transactions.filters,
					[event.target.name]: event.target.value,
				},
			},
		});
	};

	const onChangeDateFilter = (date) => {
		handleDetail({
			...details,
			transactions: {
				...details.transactions,
				filters: {
					...toggleResetFilter(
						details.transactions["filters"],
						"date"
					),
					date: date,
				},
			},
		});
	};

	const formatValue = (val, formatter = 2) => {
		return isNaN(parseFloat(val))
			? 0.0
			: parseFloat(val).toFixed(formatter);
	};

	const getStringToDateRange = (filters) => {
		let { time } = filters;
		const timeValue = [];
		if (time) {
			const key = time.last.includes("years") ? "years" : time.last;
			const value = time[key];
			if (key === "years") {
				timeValue[0] = moment(value, "YYYY").toDate().toString();
				timeValue[1] = moment(value, "YYYY")
					.endOf("year")
					.toDate()
					.toString();
			} else if (key === "months") {
				timeValue[0] = moment().subtract(value, "months").toDate();
				timeValue[1] = moment().toDate();
			} else if (key === "hours") {
				timeValue[0] = moment().subtract(value, "hours").toDate();
				timeValue[1] = moment().toDate();
			}
		}

		return timeValue;
	};

	const getTotalYear = () => {
		const numberofYear = [];
		const currentYear = new Date().getFullYear();
		for (let i = 2019; i <= parseInt(currentYear); i++) {
			numberofYear.push(i);
		}
		return numberofYear;
	};

	const setDateFilter = (filters) => {
		const { time, ...rest } = filters;
		if (filters.date?.[0]) return rest;
		let newTimeValue = getStringToDateRange(filters);
		filters = { ...rest, date: newTimeValue };
		return filters;
	};

	const toggleResetFilter = (filters, set) => {
		if (set === "date") {
			return {
				...filters,
				time: {
					years: "",
					months: "",
					days: "",
					hours: "",
					last: "",
				},
			};
		}
		return { ...filters, date: [null, null] };
	};

	return (
		<React.Fragment>
			<Col md={3} xs={3} xl={3}>
				<Card className="card-h-100">
					<CardBody>
						{/* <span className="text-muted mb-3 lh-1 d-block text-truncate">
							Total PIX Clients
						</span> */}

						<div className="d-flex flex-wrap  mb-4">
							<h5 className="card-title me-3">
								Total PIX transaction amount
							</h5>
							{/* <div className="">
								<select
									name="country"
									className="form-select form-select-sm"
									value={details.transactions.filters.country}
									onChange={onChangeFilter}
								>
									<option disabled value="Country">
										Country
									</option>
								</select>
							</div> */}
							{/* <div className="ms-auto mb-2">
								<select
									name="type"
									className="form-select form-select-sm"
									value={details.transactions.filters.type}
									onChange={onChangeFilter}
								>
									<option disabled value="Type">
										Type
									</option>
									<option value="">All</option>
									{[
										"Verified",
										"Suspended",
										"First Deposit",
										"Undeposited",
										"Document Submitted",
										"UnVerified",
									].map((item) => (
										<option key={item} value={item}>
											{item}
										</option>
									))}
								</select>
							</div> */}

							{/* <div className="ms-auto mb-2">
								<Flatpickr
									className="form-control form-control-sm d-block"
									placeholder="Date Range"
									options={{
										mode: "range",
										dateFormat: "m/d/Y",
										maxDate: new Date(),
									}}
									name="date"
									value={details.transactions.filters.date}
									onChange={onChangeDateFilter}
								/>
							</div> */}
						</div>
						<Row className="align-items-center">
							<Col >
								<span className="text-muted mb-3 lh-1 d-block text-truncate"></span>
								<h4 className="mb-3">
									<span className="counter-value">
										{pixTranscations.isLoading ? (
											<i className="bx bx-loader bx-spin font-size-20 align-middle me-2"></i>
										) : (
											<CountUp
												className="account-balance"
												start={0}
												end={formatValue(
													pixTranscations.details
														?.total
												)}
												duration={0.75}
												separator=""
												suffix={` BRL`}
												decimals={4}
											></CountUp>
										)}

										{""}
									</span>
								</h4>
							</Col>
						</Row>
					</CardBody>
				</Card>
			</Col>
		</React.Fragment>
	);
};

export default TotalPIXTransaction;
