import { all, fork } from "redux-saga/effects";

//public
import AuthSaga from "./auth/saga";
import LayoutSaga from "./layout/saga";
import bannerSaga from "./banner/saga";
import pixSaga from "./pix/saga";
import adminSaga from "./admin/saga";
import coinListSaga from "./coinList/saga";
import newCoinListSaga from "./newCoinList/saga";
import mobileMenuSaga from "./mobileMenu/saga";
import tkxEndpointSaga from "./tkxEndpoint/saga";
import dashboardSaga from "./dashboard/saga";

export default function* rootSaga() {
	yield all([
		fork(AuthSaga),
		fork(LayoutSaga),
		fork(bannerSaga),
		fork(pixSaga),
		fork(adminSaga),
		fork(coinListSaga),
		fork(newCoinListSaga),
		fork(mobileMenuSaga),
		fork(tkxEndpointSaga),
		fork(dashboardSaga),
	]);
}
