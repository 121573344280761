import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { pixTransactionStatus } from "../../common/List";
import {
	CLEAR_RESPONSE,
	PIX_CLIENT_REQUEST,
	PIX_CLIENT_FAILURE,
	PIX_CLIENT_SUCCESS,
	PIX_ALL_CLIENT_REQUEST,
	PIX_ALL_CLIENT_FAILURE,
	PIX_ALL_CLIENT_SUCCESS,
	PIX_TRANSACTION_REQUEST,
	PIX_TRANSACTION_FAILURE,
	PIX_TRANSACTION_SUCCESS,
	PIX_CLIENT_TRANSACTION_REQUEST,
	PIX_CLIENT_TRANSACTION_FAILURE,
	PIX_CLIENT_TRANSACTION_SUCCESS,
	PIX_CLIENT_REJECT_TRANSACTION_REQUEST,
	PIX_CLIENT_REJECT_TRANSACTION_FAILURE,
	PIX_CLIENT_REJECT_TRANSACTION_SUCCESS,
	PIX_CLIENT_APPROVE_TRANSACTION_REQUEST,
	PIX_CLIENT_APPROVE_TRANSACTION_FAILURE,
	PIX_CLIENT_APPROVE_TRANSACTION_SUCCESS,
	PIX_CLIENT_FEES_TRANSACTION_REQUEST,
	PIX_CLIENT_FEES_TRANSACTION_FAILURE,
	PIX_CLIENT_FEES_TRANSACTION_SUCCESS,
	PIX_CLIENT_VERIFICATION_CODE_REQUEST,
	PIX_CLIENT_VERIFICATION_CODE_FAILURE,
	PIX_CLIENT_VERIFICATION_CODE_SUCCESS,
	PIX_SETTING_REQUEST,
	PIX_SETTING_FAILURE,
	PIX_SETTING_SUCCESS,
	UPDATE_PIX_SETTING_REQUEST,
	UPDATE_PIX_SETTING_FAILURE,
	UPDATE_PIX_SETTING_SUCCESS,
	REMOVE_PIX_CLIENT_REQUEST,
	REMOVE_PIX_CLIENT_SUCCESS,
	REMOVE_PIX_CLIENT_FAILURE,
	GET_PIX_CLIENT_BALANCE_REQUEST,
	GET_PIX_CLIENT_BALANCE_FAILURE,
	GET_PIX_CLIENT_BALANCE_SUCCESS,
	GET_PIX_EXCHANGE_RATE_REQUEST,
	GET_PIX_EXCHANGE_RATE_FAILURE,
	GET_PIX_EXCHANGE_RATE_SUCCESS,
} from "./actionTypes";

import {
	getClients,
	getClient,
	getClientBalance,
	getTransactions,
	getClientTransactions,
	approveClientTransaction,
	transactionVerificationRequest,
	getTotalFeesTransaction,
	rejectClientTransaction,
	getSetting,
	updateSetting,
	removeClient,
	getExchangeRate,
} from "./services";

function* getPixClients() {
	try {
		const response = yield call(getClients);
		if (response.status === 200) {
			yield put({
				type: PIX_ALL_CLIENT_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: PIX_ALL_CLIENT_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: PIX_ALL_CLIENT_FAILURE,
			payload: {},
		});
	}
}

function* getPixClientBalance({ payload }) {
	try {
		const response = yield call(getClientBalance, payload);
		if (response.status === 200) {
			yield put({
				type: GET_PIX_CLIENT_BALANCE_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: GET_PIX_CLIENT_BALANCE_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: GET_PIX_CLIENT_BALANCE_FAILURE,
			payload: {},
		});
	}
}
function* getPixClient({ payload: { userId } }) {
	try {
		const response = yield call(getClient, userId);
		if (response.status === 200) {
			yield put({
				type: PIX_CLIENT_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: PIX_CLIENT_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: PIX_CLIENT_FAILURE,
			payload: {},
		});
	}
}
function* getPixTransactions() {
	try {
		const response = yield call(getTransactions);
		if (response.status === 200) {
			yield put({
				type: PIX_TRANSACTION_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: PIX_TRANSACTION_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: PIX_TRANSACTION_FAILURE,
			payload: {},
		});
	}
}

function* getPixClientTransactions({ payload: { uuid } }) {
	try {
		const response = yield call(getClientTransactions, uuid);
		if (response.status === 200) {
			yield put({
				type: PIX_CLIENT_TRANSACTION_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: PIX_CLIENT_TRANSACTION_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: PIX_CLIENT_TRANSACTION_FAILURE,
			payload: {},
		});
	}
}

function* approvePixClientTransactions({ payload }) {
	try {
		const response = yield call(approveClientTransaction, payload);
		if (response.status === 200) {
			yield put({
				type: PIX_CLIENT_APPROVE_TRANSACTION_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: PIX_CLIENT_APPROVE_TRANSACTION_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: PIX_CLIENT_APPROVE_TRANSACTION_FAILURE,
			payload: {},
		});
	}
}

function* getTotalFeesPixClientTransactions({ payload }) {
	try {
		const response = yield call(getTotalFeesTransaction, payload);
		if (response.status === 200) {
			yield put({
				type: PIX_CLIENT_FEES_TRANSACTION_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: PIX_CLIENT_FEES_TRANSACTION_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: PIX_CLIENT_FEES_TRANSACTION_FAILURE,
			payload: {},
		});
	}
}
function* pixTransactionVerificationRequest({ payload }) {
	try {
		const response = yield call(transactionVerificationRequest, payload);
		if (response.status === 200) {
			yield put({
				type: PIX_CLIENT_VERIFICATION_CODE_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: PIX_CLIENT_VERIFICATION_CODE_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: PIX_CLIENT_VERIFICATION_CODE_FAILURE,
			payload: {},
		});
	}
}

function* rejectPixClientTransactions({ payload }) {
	try {
		const response = yield call(rejectClientTransaction, payload);
		if (response.status === 200) {
			yield put({
				type: PIX_CLIENT_REJECT_TRANSACTION_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: PIX_CLIENT_REJECT_TRANSACTION_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: PIX_CLIENT_REJECT_TRANSACTION_FAILURE,
			payload: {},
		});
	}
}

function* getPixSettings({ payload }) {
	try {
		const response = yield call(getSetting);
		if (response.status === 200) {
			yield put({
				type: PIX_SETTING_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: PIX_SETTING_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: PIX_SETTING_FAILURE,
			payload: {},
		});
	}
}

function* updatePixSettings({ payload }) {
	try {
		const response = yield call(updateSetting, payload);
		if (response.status === 200) {
			yield put({
				type: UPDATE_PIX_SETTING_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: UPDATE_PIX_SETTING_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: UPDATE_PIX_SETTING_FAILURE,
			payload: {},
		});
	}
}

function* removePixClient({ payload: { id } }) {
	try {
		const response = yield call(removeClient, id);
		if (response.status === 200) {
			yield put({
				type: REMOVE_PIX_CLIENT_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: REMOVE_PIX_CLIENT_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: REMOVE_PIX_CLIENT_FAILURE,
			payload: {},
		});
	}
}

function* getPixExchangeRate({ payload}) {
	try {
		const response = yield call(getExchangeRate, payload);
		if (response.status === 200) {
			yield put({
				type: GET_PIX_EXCHANGE_RATE_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: GET_PIX_EXCHANGE_RATE_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: GET_PIX_EXCHANGE_RATE_FAILURE,
			payload: {},
		});
	}
}

function* saga() {
	yield takeEvery(PIX_CLIENT_REQUEST, getPixClient);
	yield takeEvery(PIX_ALL_CLIENT_REQUEST, getPixClients);
	yield takeEvery(GET_PIX_CLIENT_BALANCE_REQUEST, getPixClientBalance);
	yield takeEvery(PIX_TRANSACTION_REQUEST, getPixTransactions);
	yield takeEvery(PIX_CLIENT_TRANSACTION_REQUEST, getPixClientTransactions);
	yield takeEvery(REMOVE_PIX_CLIENT_REQUEST, removePixClient);

	yield takeEvery(
		PIX_CLIENT_APPROVE_TRANSACTION_REQUEST,
		approvePixClientTransactions
	);
	yield takeEvery(
		PIX_CLIENT_FEES_TRANSACTION_REQUEST,
		getTotalFeesPixClientTransactions
	);
	yield takeEvery(
		PIX_CLIENT_VERIFICATION_CODE_REQUEST,
		pixTransactionVerificationRequest
	);
	yield takeEvery(
		PIX_CLIENT_REJECT_TRANSACTION_REQUEST,
		rejectPixClientTransactions
	);

	yield takeEvery(PIX_SETTING_REQUEST, getPixSettings);
	yield takeEvery(UPDATE_PIX_SETTING_REQUEST, updatePixSettings);
	yield takeEvery(GET_PIX_EXCHANGE_RATE_REQUEST, getPixExchangeRate);
}

export default saga;
