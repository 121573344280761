export const ERRORS = "ERRORS";
export const CLEAR_RESPONSE = "CLEAR_RESPONSE";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const CREATE_TKX_ENDPOINT_REQUEST = "CREATE_TKX_ENDPOINT_REQUEST";
export const CREATE_TKX_ENDPOINT_SUCCESS = "CREATE_TKX_ENDPOINT_SUCCESS";
export const CREATE_TKX_ENDPOINT_FAILURE = "CREATE_TKX_ENDPOINT_FAILURE";

export const UPDATE_TKX_ENDPOINT_REQUEST = "UPDATE_TKX_ENDPOINT_REQUEST";
export const UPDATE_TKX_ENDPOINT_SUCCESS = "UPDATE_TKX_ENDPOINT_SUCCESS";
export const UPDATE_TKX_ENDPOINT_FAILURE = "UPDATE_TKX_ENDPOINT_FAILURE";

export const REMOVE_TKX_ENDPOINT_REQUEST = "REMOVE_TKX_ENDPOINT_REQUEST";
export const REMOVE_TKX_ENDPOINT_SUCCESS = "REMOVE_TKX_ENDPOINT_SUCCESS";
export const REMOVE_TKX_ENDPOINT_FAILURE = "REMOVE_TKX_ENDPOINT_FAILURE";

export const TOGGLE_TKX_ENDPOINT_REQUEST = "TOGGLE_TKX_ENDPOINT_REQUEST";
export const TOGGLE_TKX_ENDPOINT_SUCCESS = "TOGGLE_TKX_ENDPOINT_SUCCESS";
export const TOGGLE_TKX_ENDPOINT_FAILURE = "TOGGLE_TKX_ENDPOINT_FAILURE";

export const CREATE_TKX_HOST_REQUEST = "CREATE_TKX_HOST_REQUEST";
export const CREATE_TKX_HOST_SUCCESS = "CREATE_TKX_HOST_SUCCESS";
export const CREATE_TKX_HOST_FAILURE = "CREATE_TKX_HOST_FAILURE";

export const UPDATE_TKX_HOST_REQUEST = "UPDATE_TKX_HOST_REQUEST";
export const UPDATE_TKX_HOST_SUCCESS = "UPDATE_TKX_HOST_SUCCESS";
export const UPDATE_TKX_HOST_FAILURE = "UPDATE_TKX_HOST_FAILURE";

export const REMOVE_TKX_HOST_REQUEST = "REMOVE_TKX_HOST_REQUEST";
export const REMOVE_TKX_HOST_SUCCESS = "REMOVE_TKX_HOST_SUCCESS";
export const REMOVE_TKX_HOST_FAILURE = "REMOVE_TKX_HOST_FAILURE";

export const TOGGLE_TKX_HOST_REQUEST = "TOGGLE_TKX_HOST_REQUEST";
export const TOGGLE_TKX_HOST_SUCCESS = "TOGGLE_TKX_HOST_SUCCESS";
export const TOGGLE_TKX_HOST_FAILURE = "TOGGLE_TKX_HOST_FAILURE";
