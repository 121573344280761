import React, { useEffect, useState, useRef, useMemo } from "react";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Helmet } from "react-helmet";
import {
	Card,
	CardBody,
	Col,
	Nav,
	NavItem,
	NavLink,
	Row,
	TabContent,
	TabPane,
	Container,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import Avatar from "react-avatar";
import {
	getClient,
	// clearResponse,
} from "../../store/pix/actions";
import classnames from "classnames";
import TransactionDetail from "./TransactionDetail";
// import hasPermission from "../../common/HasPermission";
import HasAnyPermission from "../../common/Permission";
import TotalPIXTransaction from "../Dashboard/TotalPIXTransaction";
const ClientDetail = (props) => {
	const dispatch = useDispatch();
	const [activeTab, toggleTab] = useState("Transactions");
	const { client } = useSelector((state) => ({
		client: state.pix.client,
		isLoading: state.pix.isLoading,
		auth: state.auth,
	}));

	useEffect(() => {
		if (props.match?.params?.slug)
			dispatch(getClient(props.match.params.slug));
	}, []);

	return (
		<React.Fragment>
			<div className="page-content">
				<Helmet>
					<title>PIX Client Detail | TOKEX</title>
				</Helmet>
				<Container fluid>
					{/* Render Breadcrumbs */}
					<Breadcrumbs
						title="PIX Client Detail"
						breadcrumbItem="Client Details"
					/>

					<Row>
						<Col xl={12} lg={12}>
							<Card>
								<CardBody>
									<Row>
										<div className="col-sm order-2 order-sm-1">
											<div className="d-flex align-items-start mt-3 mt-sm-0">
												<div className="flex-shrink-0">
													<div className="avatar-xl me-3">
														<img
															src={""}
															alt=""
															className="img-fluid rounded-circle d-block"
														/>
														<Avatar
															className=""
															color={"#74788d"}
															name={
																client?.name_client ===
																undefined
																	? "Loading..."
																	: client.name_client
															}
															round={true}
															size={80}
														/>
													</div>
												</div>
												<div className="flex-grow-1">
													<div>
														<h5 className="font-size-16 mb-1">
															{client?.name_client ===
															undefined
																? "Loading..."
																: client.name_client}
														</h5>
														<p className="text-muted font-size-13">
															{client?.email_client ===
															undefined
																? "Loading.."
																: client.email_client}
														</p>
														{/* <div className="d-flex flex-wrap align-items-start gap-2 gap-lg-3 text-muted font-size-13">
															<p className="text-muted font-size-13">
																{clientDetails
																	?.client
																	?.client_id ===
																undefined
																	? "Loading.."
																	: clientDetails
																			.client
																			.client_id}
															</p>
														</div> */}

														{/* <div className="d-flex flex-wrap align-items-start gap-2 gap-lg-3 text-muted font-size-13">
															<div>
																<ReactTooltip
																	id="approve"
																	type="info"
																	effect="float"
																	place="bottom"
																>
																	<span>
																		{true
																			? "Click to un-verify user account"
																			: "Click to verify user account"}
																	</span>
																</ReactTooltip>
																<button
																	type="button"
																	data-tip
																	data-for="approve"
																	disabled={
																		!hasPermission(
																			[
																				"user verification",
																			],

																			auth.currentUserRolePermissions
																		)
																	}
																	className={`btn waves-effect btn-label waves-light w-sm ${
																		true
																			? "btn-success"
																			: true
																			? "btn-warning"
																			: "btn-danger"
																	} label-icon`}
																	// onClick={() =>
																	// 	this.toggleVerification(
																	// 		identityInfo
																	// 	)
																	// }
																>
																	<i
																		className={`bx ${
																			true
																				? "bx-check-double"
																				: "bx-x"
																		} label-icon`}
																	></i>{" "}
																	{true
																		? "Account Verified"
																		: true
																		? "Verification Pending"
																		: "Verify"}
																</button>
															</div>
														</div> */}
													</div>
												</div>
											</div>
										</div>
										<div className="col-sm-auto order-1 order-sm-2">
											<div className="d-flex align-items-start justify-content-end gap-2">
												<div>
													{/* <h5 className="font-size-16 mb-1">
														{`Account Open Date: ${moment(
															"today"
														).format(
															"DD-MM-YYYY"
														)}`}
													</h5> */}
												</div>
											</div>
										</div>
									</Row>

									<Nav className="nav-tabs-custom card-header-tabs border-top mt-4">
										<NavItem>
											<NavLink
												to="#"
												className={classnames(
													{
														active:
															activeTab ===
															"Info",
													},
													"px-3"
												)}
												onClick={() => {
													toggleTab("Info");
												}}
											>
												Info
											</NavLink>
										</NavItem>
										<NavItem>
											<NavLink
												to="#"
												className={classnames(
													{
														active:
															activeTab ===
															"Transactions",
													},
													"px-3"
												)}
												onClick={() => {
													toggleTab("Transactions");
												}}
											>
												Transactions
											</NavLink>
										</NavItem>
									</Nav>
								</CardBody>
							</Card>
							<TabContent activeTab={activeTab}>
								<TabPane tabId="Transactions">
									{props.match?.params?.slug && (
										<HasAnyPermission
											permission={[
												"view pix client list",
											]}
										>
											<TransactionDetail
												userId={props.match.params.slug}
											/>
										</HasAnyPermission>
									)}
								</TabPane>

								<TabPane tabId="Info">
									{props.match?.params?.slug && (
										<Row>
											<Col lg="12">
												<Card>
													<CardBody>
														<Row>
															<TotalPIXTransaction
																userId={
																	props.match
																		.params
																		.slug
																}
															/>
														</Row>
													</CardBody>
												</Card>
											</Col>
										</Row>
									)}
								</TabPane>
							</TabContent>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default ClientDetail;
