import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
	CREATE_COIN_LIST_REQUEST,
	CREATE_COIN_LIST_SUCCESS,
	CREATE_COIN_LIST_FAILURE,
	UPDATE_COIN_LIST_REQUEST,
	UPDATE_COIN_LIST_SUCCESS,
	UPDATE_COIN_LIST_FAILURE,
	REMOVE_COIN_LIST_REQUEST,
	REMOVE_COIN_LIST_SUCCESS,
	REMOVE_COIN_LIST_FAILURE,
	TOGGLE_COIN_LIST_REQUEST,
	TOGGLE_COIN_LIST_SUCCESS,
	TOGGLE_COIN_LIST_FAILURE,
} from "./actionTypes";

import { create, update, remove, toggle } from "./services";

function* createCoinList({ payload: { data } }) {
	try {
		const response = yield call(create, data);
		if (response.status === 200) {
			yield put({
				type: CREATE_COIN_LIST_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: CREATE_COIN_LIST_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: CREATE_COIN_LIST_FAILURE,
			payload: {},
		});
	}
}

function* updateCoinList({ payload: { data } }) {
	try {
		const response = yield call(update, data);
		if (response.status === 200) {
			yield put({
				type: UPDATE_COIN_LIST_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: UPDATE_COIN_LIST_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: UPDATE_COIN_LIST_FAILURE,
			payload: {},
		});
	}
}

function* removeCoinList({ payload: { id } }) {
	try {
		const response = yield call(remove, id);
		if (response.status === 200) {
			yield put({
				type: REMOVE_COIN_LIST_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: REMOVE_COIN_LIST_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: REMOVE_COIN_LIST_FAILURE,
			payload: {},
		});
	}
}

function* toggleCoinList({ payload: { id } }) {
	try {
		const response = yield call(toggle, id);
		if (response.status === 200) {
			yield put({
				type: TOGGLE_COIN_LIST_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: TOGGLE_COIN_LIST_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: TOGGLE_COIN_LIST_FAILURE,
			payload: response.data,
		});
	}
}

function* saga() {
	yield takeEvery(CREATE_COIN_LIST_REQUEST, createCoinList);
	yield takeEvery(UPDATE_COIN_LIST_REQUEST, updateCoinList);
	yield takeEvery(REMOVE_COIN_LIST_REQUEST, removeCoinList);
	yield takeEvery(TOGGLE_COIN_LIST_REQUEST, toggleCoinList);
}

export default saga;
