export const LOGIN_USER_ROLE_REQUEST = "LOGIN_USER_ROLE_REQUEST";
export const LOGIN_USER_ROLE_SUCCESS = "LOGIN_USER_ROLE_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const ERRORS = "ERRORS";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_REQUEST = "LOGIN_REQUEST";

export const RESEND_VERIFICATION_CODE_SUCCESS =
	"RESEND_VERIFICATION_CODE_SUCCESS";
export const RESEND_VERIFICATION_CODE_FAILURE =
	"RESEND_VERIFICATION_CODE_FAILURE";
export const RESEND_VERIFICATION_CODE_REQUEST =
	"RESEND_VERIFICATION_CODE_REQUEST";

export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILURE = "UPDATE_PROFILE_FAILURE";
export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST";

export const UPDATE_PROFILE_PASSWORD_SUCCESS =
	"UPDATE_PROFILE_PASSWORD_SUCCESS";
export const UPDATE_PROFILE_PASSWORD_FAILURE =
	"UPDATE_PROFILE_PASSWORD_FAILURE";
export const UPDATE_PROFILE_PASSWORD_REQUEST =
	"UPDATE_PROFILE_PASSWORD_REQUEST";

export const CONFIRM_LOGIN_SUCCESS = "CONFIRM_LOGIN_SUCCESS";
export const CONFIRM_LOGIN_FAILURE = "CONFIRM_LOGIN_FAILURE";
export const CONFIRM_LOGIN_REQUEST = "CONFIRM_LOGIN_REQUEST";
export const SET_LOGIN_REQUEST = "SET_LOGIN_REQUEST";
export const CLEAR_VERIFICATION_RESPONSE = "CLEAR_VERIFICATION_RESPONSE";
export const CLEAR_RESPONSE = "CLEAR_RESPONSE";

export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";
export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";

export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
