import axios from "axios";
import { apiUrl } from "../../config";

export const getClients = () =>
	axios
		.get(`${apiUrl}/payment_gateway/pix/list_clients`)
		.then((response) => response)
		.catch((err) => err.response);

export const getClient = (userId) =>
	axios
		.get(`${apiUrl}/payment_gateway/pix/client/${userId}`)
		.then((response) => response)
		.catch((err) => err.response);

export const getClientBalance = (data) =>
	axios
		.post(`${apiUrl}/admin/pix/get-tkx-user-balance`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const getTransactions = () =>
	axios
		.get(`${apiUrl}/payment_gateway/pix/list_all_transactions`)
		.then((response) => response);

export const getClientTransactions = (uuid) =>
	axios
		.get(`${apiUrl}/payment_gateway/pix/list_client_transactions/${uuid}`)
		.then((response) => response);

export const approveClientTransaction = (data) =>
	axios
		.post(`${apiUrl}/admin/pix/update_transaction`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const getTotalFeesTransaction = (data) =>
	axios
		.post(`${apiUrl}/admin/pix/get_cost`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const transactionVerificationRequest = (data) =>
	axios
		.post(`${apiUrl}/admin/pix/send_verification_request`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const rejectClientTransaction = (data) =>
	axios
		.post(`${apiUrl}/admin/pix/notify-client-transaction`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const getSetting = () =>
	axios.get(`${apiUrl}/admin/pix-setting`).then((response) => response);

export const updateSetting = (data) =>
	axios
		.put(`${apiUrl}/admin/pix-setting/update/${data._id}`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const removeClient = (id) =>
	axios
		.delete(`${apiUrl}/payment_gateway/pix/client/delete/${id}`)
		.then((response) => response);

export const getExchangeRate = (data) =>
	axios
		.post(`${apiUrl}/payment_gateway/pix/get_exchange_rate`, data)
		.then((response) => response)
		.catch((err) => err.response);
