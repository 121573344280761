import axios from "axios";
import { apiUrl } from "../../config";

export const create = (data) =>
	axios
		.post(`${apiUrl}/admin/coin-list/create`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const update = (data) =>
	axios
		.put(`${apiUrl}/admin/coin-list/update/${data._id}`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const remove = (id) =>
	axios
		.delete(`${apiUrl}/admin/coin-list/delete/${id}`)
		.then((response) => response);

export const toggle = (id) =>
	axios
		.patch(`${apiUrl}/admin/coin-list/toggle-status/${id}`)
		.then((response) => response)
		.catch((err) => err.response);
