import {
	ERRORS,
	CLEAR_RESPONSE,
	CREATE_NEW_COIN_LIST_REQUEST,
	UPDATE_NEW_COIN_LIST_REQUEST,
	REMOVE_NEW_COIN_LIST_REQUEST,
	TOGGLE_NEW_COIN_LIST_REQUEST,
} from "./actionTypes";

export const clearResponse = () => {
	return {
		type: CLEAR_RESPONSE,
		payload: {},
	};
};

export const create = (data) => {
	return {
		type: CREATE_NEW_COIN_LIST_REQUEST,
		payload: { data },
	};
};

export const update = (data) => {
	return {
		type: UPDATE_NEW_COIN_LIST_REQUEST,
		payload: { data },
	};
};

export const remove = (id) => {
	return {
		type: REMOVE_NEW_COIN_LIST_REQUEST,
		payload: { id },
	};
};

export const toggle = (id) => {
	return {
		type: TOGGLE_NEW_COIN_LIST_REQUEST,
		payload: { id },
	};
};
