import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
	CREATE_MOBILE_MENU_REQUEST,
	CREATE_MOBILE_MENU_SUCCESS,
	CREATE_MOBILE_MENU_FAILURE,
	UPDATE_MOBILE_MENU_REQUEST,
	UPDATE_MOBILE_MENU_SUCCESS,
	UPDATE_MOBILE_MENU_FAILURE,
	REMOVE_MOBILE_MENU_REQUEST,
	REMOVE_MOBILE_MENU_SUCCESS,
	REMOVE_MOBILE_MENU_FAILURE,
	TOGGLE_MOBILE_MENU_REQUEST,
	TOGGLE_MOBILE_MENU_SUCCESS,
	TOGGLE_MOBILE_MENU_FAILURE,
} from "./actionTypes";

import { create, update, remove, toggle } from "./services";

function* createMobileMenu({ payload: { data } }) {
	try {
		const response = yield call(create, data);
		if (response.status === 200) {
			yield put({
				type: CREATE_MOBILE_MENU_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: CREATE_MOBILE_MENU_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: CREATE_MOBILE_MENU_FAILURE,
			payload: {},
		});
	}
}

function* updateMobileMenu({ payload: { data } }) {
	try {
		const response = yield call(update, data);
		if (response.status === 200) {
			yield put({
				type: UPDATE_MOBILE_MENU_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: UPDATE_MOBILE_MENU_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: UPDATE_MOBILE_MENU_FAILURE,
			payload: {},
		});
	}
}

function* removeMobileMenu({ payload: { id } }) {
	try {
		const response = yield call(remove, id);
		if (response.status === 200) {
			yield put({
				type: REMOVE_MOBILE_MENU_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: REMOVE_MOBILE_MENU_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: REMOVE_MOBILE_MENU_FAILURE,
			payload: {},
		});
	}
}

function* toggleMobileMenu({ payload: { id } }) {
	try {
		const response = yield call(toggle, id);
		if (response.status === 200) {
			yield put({
				type: TOGGLE_MOBILE_MENU_SUCCESS,
				payload: response.data,
			});
		} else {
			yield put({
				type: TOGGLE_MOBILE_MENU_FAILURE,
				payload: response.data,
			});
		}
	} catch (err) {
		yield put({
			type: TOGGLE_MOBILE_MENU_FAILURE,
			payload: {},
		});
	}
}

function* saga() {
	yield takeEvery(CREATE_MOBILE_MENU_REQUEST, createMobileMenu);
	yield takeEvery(UPDATE_MOBILE_MENU_REQUEST, updateMobileMenu);
	yield takeEvery(REMOVE_MOBILE_MENU_REQUEST, removeMobileMenu);
	yield takeEvery(TOGGLE_MOBILE_MENU_REQUEST, toggleMobileMenu);
}

export default saga;
