import React, { useEffect, useState } from "react";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Helmet } from "react-helmet";
import {
	Card,
	CardBody,
	Col,
	Row,
	Container,
	CardHeader,
	CardTitle,
	Table,
	Input,
	Label,
	Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import isEmpty from "../../utils/isEmpty";
import { useSelector, useDispatch } from "react-redux";
import {
	getSetting,
	updateSetting,
	getExchangeRate,
	clearResponse,
} from "../../store/pix/actions";
import { toast } from "react-toastify";
import HasAnyPermission from "../../common/Permission";
import hasPermission from "../../common/HasPermission";
import uniqid from "uniqid";

const Setting = (props) => {
	const dispatch = useDispatch();
	var [transactionEmailBCC, setEmailBcc] = useState([
		{ key: uniqid(), value: "" },
	]);

	const { pixSetting, auth, response, isLoading, exchangeRate } = useSelector(
		(state) => ({
			pixSetting: state.pix.pixSetting,
			response: state.pix.response,
			isLoading: state.pix.isLoading,
			auth: state.auth,
			exchangeRate: state.pix.exchangeRate,
		})
	);

	useEffect(() => {
		dispatch(getSetting());
		dispatch(
			getExchangeRate({
				type: "Dollar",
			})
		);
	}, []);

	useEffect(() => {
		if (pixSetting?.transactionEmailBCC?.length) {
			setEmailBcc(
				pixSetting?.transactionEmailBCC.map((item, key) => ({
					key: uniqid(),
					value: item,
				}))
			);
		}
	}, [dispatch, pixSetting?.transactionEmailBCC]);

	useEffect(() => {
		if (response && response.code === "200") {
			toast.success(response.msg, {
				onOpen: () => {
					dispatch(clearResponse());
					dispatch(getSetting());
				},
			});
		} else if (response && response?.msg) {
			toast.error(response.msg, {
				onOpen: () => {
					dispatch(clearResponse());
				},
			});
		}
	}, [dispatch, response]);

	const handleUpdateSetting = (data) => {
		dispatch(updateSetting(data));
	};

	const handleChange = (index) => (event) => {
		const emailBcc = [...transactionEmailBCC];
		emailBcc[index]["value"] = event.target.value;
		setEmailBcc(emailBcc);
	};

	const addEmailBccField = () => {
		setEmailBcc([
			...transactionEmailBCC,
			{
				key: uniqid(),
				value: "",
			},
		]);
	};

	const removeEmailBccField = (index) => () => {
		const emailBcc = [...transactionEmailBCC];
		emailBcc.splice(index, 1);
		setEmailBcc(emailBcc);
	};

	const validation = useFormik({
		enableReinitialize: true,
		initialValues: {
			processingFees: pixSetting.processingFees ?? "",
		},
		validationSchema: Yup.object({}),
		onSubmit: (values) => {
			if (!isEmpty(values)) {
				Swal.fire({
					title: "Are you sure?",
					text: "Do you really want to update?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "Confirm",
				}).then((result) => {
					if (result.value) {
						dispatch(
							updateSetting({
								_id: pixSetting._id,
								name: Object.keys(values)?.[0],
								value: Object.values(values)?.[0],
							})
						);
					}
				});
			}
		},
	});

	const validationMinimunAmount = useFormik({
		enableReinitialize: true,
		initialValues: {
			minimunWithdrawalAmount: pixSetting.minimunWithdrawalAmount ?? "",
		},
		validationSchema: Yup.object({}),
		onSubmit: (values) => {
			if (!isEmpty(values)) {
				Swal.fire({
					title: "Are you sure?",
					text: "Do you really want to update?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "Confirm",
				}).then((result) => {
					if (result.value) {
						dispatch(
							updateSetting({
								_id: pixSetting._id,
								name: Object.keys(values)?.[0],
								value: Object.values(values)?.[0],
							})
						);
					}
				});
			}
		},
	});

	const handleEmailBCCSubmit = () => {
		Swal.fire({
			title: "Are you sure?",
			text: "Do you really want to update?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Confirm",
		}).then((result) => {
			if (result.value) {
				const formatTransactionEmailBCC = transactionEmailBCC.map(
					(item) => item.value
				);
				dispatch(
					updateSetting({
						_id: pixSetting._id,
						name: "transactionEmailBCC",
						value: formatTransactionEmailBCC,
					})
				);
			}
		});
	};

	// console.log("transactionEmailBCC", transactionEmailBCC);

	return (
		<React.Fragment>
			<div className="page-content">
				<Helmet>
					<title>PIX Setting | TOKEX</title>
				</Helmet>
				<Container fluid>
					{/* Render Breadcrumbs */}
					<Breadcrumbs title="PIX" breadcrumbItem="PIX Setting" />

					<Row>
						<Card className="border border-default">
							<CardHeader className="align-items-center d-flex bg-transparent border-default">
								<CardTitle className="mb-0 flex-grow-1">
									<h5 className="my-0 text-primary">
										<i className="mdi mdi-bullseye-arrow me-3"></i>
										Setting
									</h5>
								</CardTitle>
								{/* <div className="flex-shrink-0">
									<div className="d-flex flex-wrap gap-2 mb-0 my-n1">
										<button
											type="button"
											className="btn btn-primary waves-effect waves-light"
											// onClick={() =>
											// 	this.openModal(
											// 		"isTransferModalOpen",
											// 		"deposit"
											// 	)
											// }
										>
											Transfer Balance
										</button>
									</div>
								</div> */}
							</CardHeader>
							<CardBody>
								<Row className="mt-3">
									<Col sm={3}>
										<h5>Live</h5>
									</Col>
									<Col>
										<div
											// data-tip
											// data-for="for-transaction-auto"
											className="square-switch"
										>
											<Input
												type="checkbox"
												switch="none"
												checked={
													pixSetting.live === true
														? true
														: false
												}
												id={"square-switch-pix-live"}
												disabled={
													!hasPermission(
														["update pix setting"],
														auth.currentUserRolePermissions
													)
												}
												// defaultChecked={
												// 	pixSetting.live ??
												// 	false
												// }
												onClick={(e) => {
													handleUpdateSetting({
														_id: pixSetting._id,
														name: "live",
														value:
															!pixSetting.live ===
															true
																? true
																: false,
													});
												}}
												// onClick={(e) => {
												// 	handleUpdateSetting(
												// 		{
												// 			_id: pixSetting._id,
												// 			name: "automaticTransaction",
												// 			value: !pixSetting.automaticTransaction,
												// 		}
												// 	);
												// }}
											/>
											<Label
												htmlFor={
													"square-switch-pix-live"
												}
												data-on-label="Yes"
												data-off-label="No"
											></Label>
										</div>
									</Col>
								</Row>

								<Row className="mt-3">
									<Col sm={3}>
										<h5>Auto Transaction</h5>
									</Col>
									<Col>
										<div
											// data-tip
											// data-for="for-transaction-auto"
											className="square-switch"
										>
											<Input
												type="checkbox"
												switch="none"
												checked={
													pixSetting.automaticTransaction ===
													true
														? true
														: false
												}
												id={"square-switch-pix-auto"}
												disabled={
													!hasPermission(
														["update pix setting"],
														auth.currentUserRolePermissions
													)
												}
												// defaultChecked={
												// 	pixSetting.automaticTransaction ??
												// 	false
												// }
												onClick={(e) => {
													handleUpdateSetting({
														_id: pixSetting._id,
														name: "automaticTransaction",
														value:
															pixSetting.automaticTransaction ===
															true
																? false
																: true,
													});
												}}
												// onClick={(e) => {
												// 	handleUpdateSetting(
												// 		{
												// 			_id: pixSetting._id,
												// 			name: "automaticTransaction",
												// 			value: !pixSetting.automaticTransaction,
												// 		}
												// 	);
												// }}
											/>
											<Label
												htmlFor={
													"square-switch-pix-auto"
												}
												data-on-label="Yes"
												data-off-label="No"
											></Label>
										</div>
									</Col>
								</Row>

								<Row className="mt-3">
									<Col sm={3}>
										<h5>Processing Fees</h5>
									</Col>
									<Col>
										<div className="mb-1">
											<fieldset
												disabled={
													!hasPermission(
														["update pix setting"],
														auth.currentUserRolePermissions
													)
												}
											>
												<Form
													onSubmit={(e) => {
														e.preventDefault();
														validation.handleSubmit();
														return false;
													}}
													className="row gx-3 gy-2 align-items-center"
												>
													<Col sm={3}>
														<Input
															type="text"
															name="processingFees"
															className="form-control"
															onChange={
																validation.handleChange
															}
															onBlur={
																validation.handleBlur
															}
															value={
																validation
																	.values
																	.processingFees ||
																""
															}
														/>
													</Col>
													<div className="col-auto">
														<button
															type="submit"
															className="btn btn-primary"
														>
															Update
														</button>
													</div>
												</Form>
											</fieldset>
										</div>
									</Col>
								</Row>

								<Row className="mt-3">
									<Col sm={3}>
										<h5>Exchange Rate</h5>
									</Col>
									<Col>
										<div className="mb-1">
											<fieldset
												disabled={
													!hasPermission(
														["update pix setting"],
														auth.currentUserRolePermissions
													)
												}
											>
												<Form
													onSubmit={(e) => {
														e.preventDefault();
														return false;
													}}
													className="row gx-3 gy-2 align-items-center"
												>
													<Col sm={3}>
														<Input
															type="text"
															name="exchangeRate"
															className="form-control"
															readOnly
															value={
																exchangeRate?.price ||
																""
															}
														/>
													</Col>
													{/* <div className="col-auto">
														<button
															type="submit"
															className="btn btn-primary"
														>
															Update
														</button>
													</div> */}
												</Form>
											</fieldset>
										</div>
									</Col>
								</Row>

								<Row className="mt-3">
									<Col sm={3}>
										<h5>Minimum withdrawal amount</h5>
									</Col>
									<Col>
										<div className="mb-1">
											<fieldset
												disabled={
													!hasPermission(
														["update pix setting"],
														auth.currentUserRolePermissions
													)
												}
											>
												<Form
													onSubmit={(e) => {
														e.preventDefault();
														validationMinimunAmount.handleSubmit();
														return false;
													}}
													className="row gx-3 gy-2 align-items-center"
												>
													<Col sm={3}>
														<Input
															type="text"
															name="minimunWithdrawalAmount"
															className="form-control"
															onChange={
																validationMinimunAmount.handleChange
															}
															onBlur={
																validationMinimunAmount.handleBlur
															}
															value={
																validationMinimunAmount
																	.values
																	.minimunWithdrawalAmount ||
																""
															}
														/>
													</Col>
													<div className="col-auto">
														<button
															type="submit"
															className="btn btn-primary"
														>
															Update
														</button>
													</div>
												</Form>
											</fieldset>
										</div>
									</Col>
								</Row>

								<Row className="mt-3">
									<Col sm={3}>
										<h5>Payment Email BBC</h5>
									</Col>
									<Col>
										<div className="mb-3">
											<fieldset
												disabled={
													!hasPermission(
														["update pix setting"],
														auth.currentUserRolePermissions
													)
												}
											>
												<Form
													onSubmit={(e) => {
														e.preventDefault();
														handleEmailBCCSubmit();
														return false;
													}}
													className="row gx-3 gy-2 align-items-center"
												>
													{transactionEmailBCC.map(
														(item, index) => (
															<Row
																key={item.key}
																className="mt-3"
															>
																<Col
																	sm={3}
																	key={
																		item.key
																	}
																>
																	<Input
																		type="email"
																		name={
																			item.key
																		}
																		className="form-control"
																		required
																		onChange={handleChange(
																			index
																		)}
																		value={
																			item.value
																		}
																	/>
																</Col>

																{index ? (
																	<div className="col-auto">
																		<button
																			type="button"
																			className="btn btn-soft-danger waves-effect waves-light"
																			onClick={removeEmailBccField(
																				index
																			)}
																		>
																			<i className="bx bx-minus font-size-16 align-middle"></i>
																		</button>
																	</div>
																) : (
																	<div className="col-auto">
																		<button
																			type="button"
																			className="btn btn-soft-primary waves-effect waves-light"
																			onClick={
																				addEmailBccField
																			}
																		>
																			<i className="bx bx-plus font-size-16 align-middle"></i>
																		</button>
																	</div>
																)}
															</Row>
														)
													)}

													<div className="col-auto">
														<button
															type="submit"
															className="btn btn-primary"
														>
															Save
														</button>
													</div>
												</Form>
											</fieldset>
										</div>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default Setting;
