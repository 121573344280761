import axios from "axios";
import { apiUrl } from "../../config";

export const create = (data) =>
	axios
		.post(`${apiUrl}/admin/tkx-endpoint/create`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const update = (data) =>
	axios
		.put(`${apiUrl}/admin/tkx-endpoint/update/${data._id}`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const remove = (id) =>
	axios
		.delete(`${apiUrl}/admin/tkx-endpoint/delete/${id}`)
		.then((response) => response);

export const toggle = (id) =>
	axios
		.patch(`${apiUrl}/admin/tkx-endpoint/toggle-status/${id}`)
		.then((response) => response)
		.catch((err) => err.response);

export const createHost = (data) =>
	axios
		.post(`${apiUrl}/admin/tkx-endpoint/tkx-host-create`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const updateHost = (data) =>
	axios
		.put(`${apiUrl}/admin/tkx-endpoint/tkx-host-update/${data._id}`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const removeHost = (id) =>
	axios
		.delete(`${apiUrl}/admin/tkx-endpoint/tkx-host-delete/${id}`)
		.then((response) => response);

export const toggleHost = (id) =>
	axios
		.patch(`${apiUrl}/admin/tkx-endpoint/tkx-host-toggle-status/${id}`)
		.then((response) => response)
		.catch((err) => err.response);
