export const ERRORS = "ERRORS";
export const CLEAR_RESPONSE = "CLEAR_RESPONSE";

export const TOTAL_PIX_TRANSACTION_REQUEST = "TOTAL_PIX_TRANSACTION_REQUEST";
export const TOTAL_PIX_TRANSACTION_FAILURE = "TOTAL_PIX_TRANSACTION_FAILURE";
export const TOTAL_PIX_TRANSACTION_SUCCESS = "TOTAL_PIX_TRANSACTION_SUCCESS";

export const TOTAL_PIX_CLIENT_REQUEST = "TOTAL_PIX_CLIENT_REQUEST";
export const TOTAL_PIX_CLIENT_FAILURE = "TOTAL_PIX_CLIENT_FAILURE";
export const TOTAL_PIX_CLIENT_SUCCESS = "TOTAL_PIX_CLIENT_SUCCESS";
