import React, { useEffect, useState, useRef, useMemo } from "react";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Helmet } from "react-helmet";
import { Card, CardBody, Col, Container, Row, Label, Input } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import hasPermission from "../../common/HasPermission";
import HasAnyPermission from "../../common/Permission";
import ReactDataTable from "../../common/ReactDataTable";
import { apiUrl } from "../../config";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import isEmpty from "../../utils/isEmpty";

import { removePixClient, clearResponse } from "../../store/pix/actions";

const Client = (props) => {
	const dispatch = useDispatch();
	const refreshTableData = useRef(null);
	const { response, auth } = useSelector((state) => ({
		response: state.pix.response,
		auth: state.auth,
	}));
	useEffect(() => {
		if (response && response.code === "200") {
			refreshTableData.current();
			toast.success(response.msg, {
				onOpen: () => {
					dispatch(clearResponse());
				},
			});
		} else if (response && response?.msg) {
			refreshTableData.current();
			toast.error(response.msg, {
				onOpen: () => {
					dispatch(clearResponse());
				},
			});
		}
	}, [dispatch, response]);

	const showClientDetail = (clientId) => {
		props.history.push(`/pix-client-details/${clientId}`);
	};

	const removeItem = (id) => {
		if (!isEmpty(id)) {
			Swal.fire({
				title: "Are you sure?",
				text: "Do you really want to delete?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "Confirm",
			}).then((result) => {
				if (result.value) {
					dispatch(removePixClient(id));
				}
			});
		}
	};

	const columns = () => [
		{
			label: "Client UUID",
			name: "client_uuid",
			options: {
				filter: false,
				sort: false,
			},
		},

		{
			label: "Client UserID",
			name: "userId",
			options: {
				filter: false,
				sort: false,
			},
		},

		{
			label: "Client CPF",
			name: "cpf_client",
			options: {
				filter: false,
				sort: false,
			},
		},

		{
			label: "Client Name",
			name: "name_client",
			options: {
				filter: false,
				sort: false,
			},
		},

		{
			label: "Email",
			name: "email_client",
			options: {
				filter: false,
				sort: false,
			},
		},

		{
			label: "Status",
			name: "activate",
			options: {
				customBodyRender: (activate) => {
					let type = "success";
					if (!activate) type = "danger";
					return (
						<div
							className={
								"badge badge-soft-" + type + " font-size-12"
							}
						>
							{activate ? "Active" : "Inactive"}
						</div>
					);
				},
				customFilterListOptions: {
					render: (v) =>
						v.map((l) => (l === "true" ? "Active" : "Inactive")),
				},
				filterType: "custom",
				filterOptions: {
					names: [],
					display: (filterList, onChange, index, column) => {
						const optionValues = ["Active", "Inactive"];
						return (
							<div className="mb-3">
								<label
									htmlFor="choices-single-no-sorting"
									className="form-label font-size-13 text-muted"
								></label>
								<select
									value={filterList[index][0] || ""}
									onChange={(event) => {
										filterList[index][0] =
											event.target.value;
										onChange(
											filterList[index],
											index,
											column
										);
									}}
									className="form-control"
									name="choices-single-no-sorting"
								>
									<option disabled value="">
										Status
									</option>
									{optionValues.map((item) => (
										<option
											key={item}
											value={
												item === "Active" ? true : false
											}
										>
											{item}
										</option>
									))}
								</select>
							</div>
						);
					},
				},
				sort: false,
			},
		},

		{
			label: "Actions",
			name: "action",
			options: {
				filter: false,
				sort: false,
				empty: true,
				download: true,
				display: hasPermission(
					["view pix client list", "view pix client detail"],
					auth.currentUserRolePermissions
				),
				viewColumns: hasPermission(
					["view pix client list", "view pix client detail"],
					auth.currentUserRolePermissions
				),
				customBodyRender: (rowData) => {
					return (
						<div className="text-center">
							<HasAnyPermission
								permission={[
									"view pix client list",
									"view pix client detail",
								]}
							>
								<button
									onClick={(e) => {
										e.preventDefault();
										showClientDetail(rowData.userId);
									}}
									type="button"
									className="btn btn-sm btn-primary waves-effect waves-light"
								>
									View
								</button>
							</HasAnyPermission>
							&nbsp;
							{rowData.activate ? null : (
								<HasAnyPermission
									permission={["delete pix client"]}
								>
									<button
										onClick={(e) =>
											removeItem(rowData.userId)
										}
										type="button"
										className="btn btn-sm btn-soft-danger waves-effect waves-light"
									>
										<i className="bx bx-trash font-size-16 align-middle"></i>
									</button>
								</HasAnyPermission>
							)}
						</div>
					);
				},
			},
		},
	];

	const resultFormatter = (result) => {
		return result.docs.map((item) => {
			return {
				...item,
				action: item,
			};
		});
	};

	return (
		<React.Fragment>
			<div className="page-content">
				<Helmet>
					<title>PIX Client | TOKEX</title>
				</Helmet>
				<Container fluid>
					{/* Render Breadcrumbs */}
					<Breadcrumbs title="PIX Client" breadcrumbItem="Clients" />
					<Row>
						<Col lg="12">
							<Card>
								<CardBody>
									<Row>
										<Col xl="12">
											<div className="table-rep-plugin">
												<div className="table-responsive">
													<HasAnyPermission
														permission={[
															"view pix client list",
														]}
													>
														<ReactDataTable
															url={`${apiUrl}/payment_gateway/pix/client/pagination`}
															columns={columns()}
															resultFormatter={
																resultFormatter
															}
															setRefresh={
																refreshTableData
															}
															disableFilterIcon={
																false
															}
															disableSearchIcon={
																false
															}
															origin={
																<div className="row">
																	<div className="col-auto h4"></div>
																</div>
															}
															rowsPerPage={10}
														/>
													</HasAnyPermission>
												</div>
											</div>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default Client;
