import React, { useEffect, useState, useRef, useMemo } from "react";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Helmet } from "react-helmet";
import TransactionDetail from "./TransactionDetail";
import { Container } from "reactstrap";

const Transaction = (props) => {
	return (
		<React.Fragment>
			<div className="page-content">
				<Helmet>
					<title>PIX User Transactions | TOKEX</title>
				</Helmet>
				<Container fluid>
					{/* Render Breadcrumbs */}
					<Breadcrumbs
						title="Users Transactions"
						breadcrumbItem="Transactions"
					/>

					<TransactionDetail userId={null} />
				</Container>
			</div>
		</React.Fragment>
	);
};

export default Transaction;
