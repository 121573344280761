import {
	ERRORS,
	CLEAR_ERRORS,
	CLEAR_RESPONSE,
	CREATE_TKX_ENDPOINT_SUCCESS,
	CREATE_TKX_ENDPOINT_FAILURE,
	UPDATE_TKX_ENDPOINT_SUCCESS,
	UPDATE_TKX_ENDPOINT_FAILURE,
	REMOVE_TKX_ENDPOINT_SUCCESS,
	REMOVE_TKX_ENDPOINT_FAILURE,
	TOGGLE_TKX_ENDPOINT_SUCCESS,
	TOGGLE_TKX_ENDPOINT_FAILURE,
} from "./actionTypes";

const initialState = {
	errors: {},
	response: {},
	responseHost: {},
	loader: false,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case ERRORS:
			return {
				...state,
				errors: action.payload,
				loading: false,
			};

		case CLEAR_RESPONSE:
			return {
				...state,
				response: {},
			};

		case CLEAR_ERRORS:
			return {
				...state,
				errors: {},
			};

		case REMOVE_TKX_ENDPOINT_SUCCESS:
		case REMOVE_TKX_ENDPOINT_FAILURE:
		case TOGGLE_TKX_ENDPOINT_SUCCESS:
		case TOGGLE_TKX_ENDPOINT_FAILURE:
			return {
				...state,
				response: action.payload,
				errors: {},
			};

		case CREATE_TKX_ENDPOINT_SUCCESS:
		case UPDATE_TKX_ENDPOINT_SUCCESS:
			return {
				...state,
				response: action.payload,
				errors: {},
			};
		case CREATE_TKX_ENDPOINT_FAILURE:
		case UPDATE_TKX_ENDPOINT_FAILURE:
			return {
				...state,
				response: action.payload,
				errors: action.payload?.errors,
			};

		default:
			return state;
	}
};

export default reducer;
