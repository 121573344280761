import {
	ERRORS,
	CLEAR_RESPONSE,
	PIX_CLIENT_REQUEST,
	PIX_CLIENT_FAILURE,
	PIX_CLIENT_SUCCESS,
	PIX_ALL_CLIENT_REQUEST,
	PIX_TRANSACTION_REQUEST,
	PIX_TRANSACTION_FAILURE,
	PIX_TRANSACTION_SUCCESS,
	PIX_CLIENT_TRANSACTION_REQUEST,
	PIX_CLIENT_TRANSACTION_FAILURE,
	PIX_CLIENT_TRANSACTION_SUCCESS,
	PIX_CLIENT_REJECT_TRANSACTION_REQUEST,
	PIX_CLIENT_REJECT_TRANSACTION_FAILURE,
	PIX_CLIENT_REJECT_TRANSACTION_SUCCESS,
	PIX_CLIENT_APPROVE_TRANSACTION_REQUEST,
	PIX_CLIENT_APPROVE_TRANSACTION_FAILURE,
	PIX_CLIENT_APPROVE_TRANSACTION_SUCCESS,
	CLEAR_PIX_CLIENT_FEES_TRANSACTION,
	PIX_SETTING_REQUEST,
	PIX_SETTING_FAILURE,
	PIX_SETTING_SUCCESS,
	UPDATE_PIX_SETTING_REQUEST,
	UPDATE_PIX_SETTING_FAILURE,
	UPDATE_PIX_SETTING_SUCCESS,
	REMOVE_PIX_CLIENT_REQUEST,
	REMOVE_PIX_CLIENT_SUCCESS,
	REMOVE_PIX_CLIENT_FAILURE,
	PIX_CLIENT_FEES_TRANSACTION_REQUEST,
	PIX_CLIENT_FEES_TRANSACTION_FAILURE,
	PIX_CLIENT_FEES_TRANSACTION_SUCCESS,
	PIX_CLIENT_VERIFICATION_CODE_REQUEST,
	PIX_CLIENT_VERIFICATION_CODE_FAILURE,
	PIX_CLIENT_VERIFICATION_CODE_SUCCESS,
	GET_PIX_CLIENT_BALANCE_REQUEST,
	GET_PIX_CLIENT_BALANCE_FAILURE,
	GET_PIX_CLIENT_BALANCE_SUCCESS,
	GET_PIX_EXCHANGE_RATE_REQUEST,
	GET_PIX_EXCHANGE_RATE_FAILURE,
	GET_PIX_EXCHANGE_RATE_SUCCESS,
} from "./actionTypes";

export const clearResponse = () => {
	return {
		type: CLEAR_RESPONSE,
		payload: {},
	};
};

export const getClients = () => {
	return {
		type: PIX_ALL_CLIENT_REQUEST,
		payload: {},
	};
};

export const getClientBalance = (data) => {
	return {
		type: GET_PIX_CLIENT_BALANCE_REQUEST,
		payload: data,
	};
};

export const getClient = (userId) => {
	return {
		type: PIX_CLIENT_REQUEST,
		payload: { userId },
	};
};

export const getTransactions = () => {
	return {
		type: PIX_TRANSACTION_REQUEST,
		payload: {},
	};
};

export const getClientTransactions = (uuid) => {
	return {
		type: PIX_CLIENT_TRANSACTION_REQUEST,
		payload: { uuid },
	};
};

export const getTotalFeesTransaction = (data) => {
	return {
		type: PIX_CLIENT_FEES_TRANSACTION_REQUEST,
		payload: data,
	};
};

export const transactionVerificationRequest = (data) => {
	return {
		type: PIX_CLIENT_VERIFICATION_CODE_REQUEST,
		payload: data,
	};
};

export const approveTransaction = (data) => {
	return {
		type: PIX_CLIENT_APPROVE_TRANSACTION_REQUEST,
		payload: data,
	};
};

export const notifyUserTransaction = (data) => {
	return {
		type: PIX_CLIENT_REJECT_TRANSACTION_REQUEST,
		payload: data,
	};
};

export const getSetting = () => {
	return {
		type: PIX_SETTING_REQUEST,
		payload: {},
	};
};

export const getExchangeRate = (data) => {
	return {
		type: GET_PIX_EXCHANGE_RATE_REQUEST,
		payload: data,
	};
};

export const updateSetting = (data) => {
	return {
		type: UPDATE_PIX_SETTING_REQUEST,
		payload: data,
	};
};

export const removePixClient = (id) => {
	return {
		type: REMOVE_PIX_CLIENT_REQUEST,
		payload: { id },
	};
};

export const clearPixClientFeesTranaction = () => {
	return {
		type: CLEAR_PIX_CLIENT_FEES_TRANSACTION,
		payload: {},
	};
};
