import React from "react";
import { Redirect } from "react-router-dom";

import Dashboard from "../pages/Dashboard/index";
import Banner from "../pages/Banner";
import Admin from "../pages/Admin";
import Role from "../pages/Admin/Role";
import TKXPAYSetting from "../pages/Admin/TKXPAYSetting";
import PixClient from "../pages/PIX/Client";
import PixSetting from "../pages/PIX/Setting";
import ClientDetail from "../pages/PIX/ClientDetail";
import PixTransactionDetail from "../pages/PIX/Transaction";
import userProfile from "../pages/Authentication/user-profile";
import CoinList from "../pages/CoinList";
import NewCoinList from "../pages/NewCoinList";
import MobileMenu from "../pages/MobileMenu";
import TkxEndpoint from "../pages/TkxEndpoint";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import ForgotPassword from "../pages/Authentication/ForgotPassword";

const userRoutes = [
	//dashboard
	{ path: "/dashboard", component: Dashboard },
	//profile
	{
		path: "/profile",
		component: userProfile,
	},
	{
		path: "/admins",
		exact: true,
		component: Admin,
		permission: ["admin add", "admin list"],
	},
	{
		path: "/roles",
		exact: true,
		component: Role,
		permission: ["role add", "role list"],
	},

	//banners
	{
		path: "/banners",
		exact: true,
		component: Banner,
		permission: ["mobile banner list", "mobile banner add"],
	},

	//Mobile Home coin list
	{
		path: "/coin-list",
		exact: true,
		component: CoinList,
		permission: ["coin list", "coin list add"],
	},

	//mobile home new coin list
	{
		path: "/new-coin-list",
		exact: true,
		component: NewCoinList,
		permission: ["coin list", "coin list add"],
	},

	//mobile menu List
	{
		path: "/mobile-menu",
		exact: true,
		component: MobileMenu,
		permission: ["mobile menu list", "mobile menu add"],
	},
	//mobile menu List
	{
		path: "/tkx-endpoint",
		exact: true,
		component: TkxEndpoint,
		permission: ["tkx endpoint list", "tkx endpoint add"],
	},

	{
		path: "/pix-client",
		exact: true,
		component: PixClient,
		permission: ["view pix client list"],
	},

	{
		path: "/pix-transactions",
		exact: true,
		component: PixTransactionDetail,
		permission: ["view pix transactions"],
	},

	{
		path: "/pix-client-details/:slug",
		exact: true,
		component: ClientDetail,
		permission: ["view pix client detail"],
	},

	{
		path: "/pix-setting",
		exact: true,
		component: PixSetting,
		permission: ["view pix setting"],
	},
	{
		path: "/tkxpay-url-setting",
		exact: true,
		component: TKXPAYSetting,
		permission: ["view pix setting"],
	},

	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const authRoutes = [
	//authencation page
	{ path: "/logout", exact: true, component: Logout },
	{ path: "/login", exact: true, component: Login },
	{
		path: "/forgot-password/:token/:tokenKey",
		exact: true,
		component: ForgotPassword,
	},
	{ path: "/forgot-password", exact: true, component: ForgotPassword },
];

export { userRoutes, authRoutes };
