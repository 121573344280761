import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import CountUp from "react-countup";
import { isEmpty } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import {
	totalClient,
	// totalTransaction,
	clearResponse,
} from "../../store/dashboard/actions";
const Client = (props) => {
	const dispatch = useDispatch();
	const { pixClients } = useSelector((state) => ({
		pixClients: state.dashboard.pixClients,
	}));
	const [details, handleDetail] = useState({
		clients: {
			filters: {
				// type: "",
				date: [null, null],
				// time: {
				// 	years: "",
				// 	months: "",
				// 	days: "",
				// 	hours: "",
				// 	last: "",
				// },
			},
			// search: "",
			// sort: "",
			// sortColumn: "",
		},
	});

	useEffect(() => {
		const filters = props.setDateFilter(details.clients.filters);
		dispatch(
			totalClient({
				...details.clients,
				filters: JSON.stringify(filters),
			})
		);
	}, [details.clients]);

	const onChangeFilter = (event) => {
		handleDetail({
			...details,
			clients: {
				...details.clients,
				filters: {
					...details.clients.filters,
					[event.target.name]: event.target.value,
				},
			},
		});
		// this.getUser();
	};

	const onChangeDateFilter = (date) => {
		handleDetail({
			...details,
			clients: {
				...details.clients,
				filters: {
					...props.toggleResetFilter(
						details.clients["filters"],
						"date"
					),
					date: date,
				},
			},
		});
	};
	return (
		<React.Fragment>
			<Col md={3} xs={3} xl={3}>
				<Card className="card-h-100">
					<CardBody>
						{/* <span className="text-muted mb-3 lh-1 d-block text-truncate">
							Total PIX Clients
						</span> */}

						<div className="d-flex flex-wrap  mb-4">
							<h5 className="card-title me-3">
								Total PIX Clients
							</h5>
							{/* <div className="">
								<select
									name="country"
									className="form-select form-select-sm"
									value={details.clients.filters.country}
									onChange={onChangeFilter}
								>
									<option disabled value="Country">
										Country
									</option>
								</select>
							</div> */}
							{/* <div className="ms-auto mb-2">
								<select
									name="type"
									className="form-select form-select-sm"
									value={details.clients.filters.type}
									onChange={onChangeFilter}
								>
									<option disabled value="Type">
										Type
									</option>
									<option value="">All</option>
									{[
										"Verified",
										"Suspended",
										"First Deposit",
										"Undeposited",
										"Document Submitted",
										"UnVerified",
									].map((item) => (
										<option key={item} value={item}>
											{item}
										</option>
									))}
								</select>
							</div> */}

							{/* <div className="ms-auto mb-2">
								<Flatpickr
									className="form-control form-control-sm d-block"
									placeholder="Date Range"
									options={{
										mode: "range",
										dateFormat: "m/d/Y",
										maxDate: new Date(),
									}}
									name="date"
									value={details.clients.filters.date}
									onChange={onChangeDateFilter}
								/>
							</div> */}
						</div>
						<Row className="align-items-center">
							<Col>
								<span className="text-muted mb-3 lh-1 d-block text-truncate"></span>
								<h4 className="mb-3">
									<span className="counter-value">
										{pixClients.isLoading ? (
											<i className="bx bx-loader bx-spin font-size-20 align-middle me-2"></i>
										) : (
											<CountUp
												className="account-balance"
												start={0}
												end={props.formatValue(
													pixClients.details?.total
												)}
												duration={0.75}
												separator=""
												suffix={``}
												// decimals={0}
											></CountUp>
										)}

										{""}
									</span>
								</h4>
							</Col>
						</Row>
					</CardBody>
				</Card>
			</Col>
		</React.Fragment>
	);
};

export default Client;
