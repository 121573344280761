import React, { useEffect, useState, useRef, useMemo } from "react";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Helmet } from "react-helmet";
import {
	Card,
	CardBody,
	Col,
	Container,
	Row,
	Modal,
	ModalHeader,
	ModalBody,
	FormFeedback,
	Form,
	Label,
	Input,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
//redux
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import HasAnyPermission from "../../common/Permission";
import hasPermission from "../../common/HasPermission";
import ReactDataTable from "../../common/ReactDataTable";
import isEmpty from "../../utils/isEmpty";
import { apiUrl } from "../../config";
import Swal from "sweetalert2";

import {
	create,
	update,
	remove,
	toggle,
	clearResponse,
} from "../../store/coinList/actions";

const CoinList = (props) => {
	const dispatch = useDispatch();
	const refreshTableData = useRef(null);
	const { response, auth } = useSelector((state) => ({
		response: state.coinList.response,
		auth: state.auth,
	}));

	const [isOpenAddEditModal, toggleAddEditModal] = useState(false);
	const [details, handleFormData] = useState({
		active: true,
	});

	useEffect(() => {
		if (response && response.code === "200") {
			refreshTableData.current();
			if (isOpenAddEditModal) toggleAddEditModal(!isOpenAddEditModal);
			toast.success(response.msg, {
				onOpen: () => {
					dispatch(clearResponse());
				},
			});
		} else if (response && response?.msg) {
			refreshTableData.current();
			toast.error(response.msg, {
				onOpen: () => {
					dispatch(clearResponse());
				},
			});
		}
	}, [dispatch, response]);

	const removeItem = (id) => {
		if (!isEmpty(id)) {
			Swal.fire({
				title: "Are you sure?",
				text: "Do you really want to delete?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "Confirm",
			}).then((result) => {
				if (result.value) {
					dispatch(remove(id));
				}
			});
		}
	};

	// const toggleItem = (id) => {
	// 	if (!isEmpty(id)) {
	// 		dispatch(toggle(id));
	// 	}
	// };

	let validationSchema = {
		name: Yup.string().required("Please enter name."),
		symbol: Yup.string().required("Please enter symbol."),
		network: Yup.string().required("Please enter network."),
		details: Yup.string().required("Please enter details."),
	};
	const useFormikOptions = {
		enableReinitialize: true,
		initialValues: {
			name: details && details.name ? details.name : "",
			symbol: details && details.symbol ? details.symbol : "",
			network: details && details.network ? details.network : "",
			details: details && details.details ? details.details : "",
		},
		validationSchema: Yup.object(validationSchema),
		onSubmit: (values) => {
			if (details._id) {
				dispatch(
					update({
						...values,
						_id: details._id,
					})
				);
			} else {
				dispatch(create(values));
			}
		},
	};
	const validation = useFormik(useFormikOptions);

	const handleAddEditModal = (data = null) => {
		if (!isEmpty(data) && data?._id) {
			handleFormData(data);
		} else {
			handleFormData({
				active: true,
			});
			validation.resetForm();
		}
		toggleAddEditModal(!isOpenAddEditModal);
	};

	const toggleItem = (id) => {
		if (!isEmpty(id)) {
			dispatch(toggle(id));
		}
	};

	const columns = () => [
		{
			label: "Name",
			name: "name",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			label: "Symbol",
			name: "symbol",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			label: "Network",
			name: "network",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			label: "Details",
			name: "details",
			options: {
				filter: false,
				sort: false,
			},
		},
		{
			name: "active",
			label: "Active",
			options: {
				filter: false,
				sort: false,
				download: false,
				customBodyRender: (rowData) => (
					<div className="square-switch">
						<Input
							type="checkbox"
							id={`square-switch-${rowData._id}-coin-list`}
							switch="none"
							checked={rowData.active}
							onClick={() => {
								toggleItem(rowData._id);
							}}
							onChange={() => {
								toggleItem(rowData._id);
							}}
						/>
						<Label
							htmlFor={`square-switch-${rowData._id}-coin-list`}
							data-on-label="Yes"
							data-off-label="No"
						></Label>
					</div>
				),
			},
		},
		{
			label: "Actions",
			name: "action",
			options: {
				filter: false,
				sort: false,
				empty: true,
				download: false,
				display: hasPermission(
					["coin list update", "coin list delete", "coin list view"],
					auth.currentUserRolePermissions
				),
				viewColumns: hasPermission(
					["coin list update", "coin list delete", "coin list view"],
					auth.currentUserRolePermissions
				),
				customBodyRender: (data) => {
					return (
						<div className="text-center">
							<HasAnyPermission
								permission={[
									"coin list update",
									"coin list view",
								]}
							>
								<button
									onClick={(e) => handleAddEditModal(data)}
									type="button"
									className="btn btn-soft-primary waves-effect waves-light"
								>
									<i className="bx bx-edit-alt font-size-16 align-middle"></i>
								</button>
							</HasAnyPermission>
							&nbsp;
							<HasAnyPermission permission={["coin list delete"]}>
								<button
									onClick={(e) => removeItem(data._id)}
									type="button"
									className="btn btn-soft-danger waves-effect waves-light"
								>
									<i className="bx bx-trash font-size-16 align-middle"></i>
								</button>
							</HasAnyPermission>
						</div>
					);
				},
			},
		},
	];

	const resultFormatter = (result) => {
		return result.docs.map((item) => {
			return {
				...item,
				active: item,
				action: item,
			};
		});
	};

	const couldHaveAddUpdatePermission = () => {
		const isUpdatePermission = hasPermission(
			["coin list update"],
			auth.currentUserRolePermissions
		);
		const isAddPermission = hasPermission(
			["coin list add"],
			auth.currentUserRolePermissions
		);
		if (isUpdatePermission && isAddPermission) return true;
		else if (isUpdatePermission && !isEmpty(details._id)) return true;
		else if (isAddPermission && isEmpty(details._id)) return true;
		else return false;
	};

	return (
		<React.Fragment>
			<div className="page-content">
				<Helmet>
					<title>CoinList | TOKEX</title>
				</Helmet>
				<Container fluid>
					{/* Render Breadcrumbs */}
					<Breadcrumbs title="CoinList" breadcrumbItem="List" />
					<Row>
						<Col lg="12">
							<Card>
								<CardBody>
									<Row>
										<Col xl="12">
											<div className="table-rep-plugin">
												<div className="table-responsive">
													<HasAnyPermission
														permission={[
															"coin list",
														]}
													>
														<ReactDataTable
															url={`${apiUrl}/admin/coin-list/pagination`}
															columns={columns()}
															resultFormatter={
																resultFormatter
															}
															setRefresh={
																refreshTableData
															}
															disableFilterIcon={
																false
															}
															disableSearchIcon={
																false
															}
															origin={
																<div className="row">
																	<div className="col-auto h4">
																		CoinList
																		&nbsp;
																		<HasAnyPermission
																			permission={[
																				"coin list add",
																			]}
																		>
																			<button
																				onClick={() => {
																					handleAddEditModal();
																				}}
																				type="button"
																				className="btn btn-primary waves-effect waves-light"
																			>
																				<i className="bx bx-plus-medical font-size-16 align-middle"></i>
																			</button>
																		</HasAnyPermission>
																	</div>
																</div>
															}
															rowsPerPage={10}
														/>
													</HasAnyPermission>

													<Modal
														isOpen={
															isOpenAddEditModal
														}
														toggle={
															handleAddEditModal
														}
														backdrop={"static"}
														size="lg"
														centered={true}
													>
														<ModalHeader
															toggle={
																handleAddEditModal
															}
															tag="h4"
														>
															{details?._id
																? "Edit"
																: "Add"}
														</ModalHeader>
														<ModalBody>
															<Form
																// form={true}
																// onSubmit={
																// 	validation.handleSubmit
																// }
																disabled
																onSubmit={(
																	e
																) => {
																	e.preventDefault();
																	validation.handleSubmit();
																	return false;
																}}
															>
																<fieldset
																	disabled={
																		!couldHaveAddUpdatePermission()
																	}
																>
																	<Row
																		form={
																			"true"
																		}
																	>
																		<Col
																			xs={
																				12
																			}
																		>
																			<div className="mb-3">
																				<Label className="form-label">
																					Name
																				</Label>
																				<Input
																					name="name"
																					type="text"
																					onChange={
																						validation.handleChange
																					}
																					onBlur={
																						validation.handleBlur
																					}
																					value={
																						validation
																							.values
																							.name ||
																						""
																					}
																					invalid={
																						validation
																							.touched
																							.name &&
																						validation
																							.errors
																							.name
																							? true
																							: false
																					}
																				/>
																				{validation
																					.touched
																					.name &&
																				validation
																					.errors
																					.name ? (
																					<FormFeedback type="invalid">
																						{
																							validation
																								.errors
																								.name
																						}
																					</FormFeedback>
																				) : null}
																			</div>
																		</Col>

																		<Col
																			xs={
																				12
																			}
																		>
																			<div className="mb-3">
																				<Label className="form-label">
																					Symbol
																				</Label>
																				<Input
																					name="symbol"
																					type="text"
																					onChange={
																						validation.handleChange
																					}
																					onBlur={
																						validation.handleBlur
																					}
																					value={
																						validation
																							.values
																							.symbol ||
																						""
																					}
																					invalid={
																						validation
																							.touched
																							.symbol &&
																						validation
																							.errors
																							.symbol
																							? true
																							: false
																					}
																				/>
																				{validation
																					.touched
																					.symbol &&
																				validation
																					.errors
																					.symbol ? (
																					<FormFeedback type="invalid">
																						{
																							validation
																								.errors
																								.symbol
																						}
																					</FormFeedback>
																				) : null}
																			</div>
																		</Col>

																		<Col
																			xs={
																				12
																			}
																		>
																			<div className="mb-3">
																				<Label className="form-label">
																					Network
																				</Label>
																				<Input
																					name="network"
																					type="text"
																					onChange={
																						validation.handleChange
																					}
																					onBlur={
																						validation.handleBlur
																					}
																					value={
																						validation
																							.values
																							.network ||
																						""
																					}
																					invalid={
																						validation
																							.touched
																							.network &&
																						validation
																							.errors
																							.network
																							? true
																							: false
																					}
																				/>
																				{validation
																					.touched
																					.network &&
																				validation
																					.errors
																					.network ? (
																					<FormFeedback type="invalid">
																						{
																							validation
																								.errors
																								.network
																						}
																					</FormFeedback>
																				) : null}
																			</div>
																		</Col>

																		<Col
																			xs={
																				12
																			}
																		>
																			<div className="mb-3">
																				<Label className="form-label">
																					Details
																				</Label>
																				<Input
																					name="details"
																					type="text"
																					onChange={
																						validation.handleChange
																					}
																					onBlur={
																						validation.handleBlur
																					}
																					value={
																						validation
																							.values
																							.details ||
																						""
																					}
																					invalid={
																						validation
																							.touched
																							.details &&
																						validation
																							.errors
																							.details
																							? true
																							: false
																					}
																				/>
																				{validation
																					.touched
																					.details &&
																				validation
																					.errors
																					.details ? (
																					<FormFeedback type="invalid">
																						{
																							validation
																								.errors
																								.details
																						}
																					</FormFeedback>
																				) : null}
																			</div>
																		</Col>
																	</Row>
																	<Row>
																		<Col>
																			<div className="text-end mt-3">
																				<button
																					type="submit"
																					className="btn btn-success save-user"
																				>
																					Save
																				</button>
																			</div>
																		</Col>
																	</Row>
																</fieldset>
															</Form>
														</ModalBody>
													</Modal>
												</div>
											</div>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default CoinList;
