export const ERRORS = "ERRORS";
export const CLEAR_RESPONSE = "CLEAR_RESPONSE";

export const PIX_CLIENT_REQUEST = "PIX_CLIENT_REQUEST";
export const PIX_CLIENT_FAILURE = "PIX_CLIENT_FAILURE";
export const PIX_CLIENT_SUCCESS = "PIX_CLIENT_SUCCESS";

export const GET_PIX_CLIENT_BALANCE_REQUEST = "GET_PIX_CLIENT_BALANCE_REQUEST";
export const GET_PIX_CLIENT_BALANCE_FAILURE = "GET_PIX_CLIENT_BALANCE_FAILURE";
export const GET_PIX_CLIENT_BALANCE_SUCCESS = "GET_PIX_CLIENT_BALANCE_SUCCESS";

export const PIX_ALL_CLIENT_REQUEST = "PIX_ALL_CLIENT_REQUEST";
export const PIX_ALL_CLIENT_FAILURE = "PIX_ALL_CLIENT_FAILURE";
export const PIX_ALL_CLIENT_SUCCESS = "PIX_ALL_CLIENT_SUCCESS";

export const REMOVE_PIX_CLIENT_REQUEST = "REMOVE_PIX_CLIENT_REQUEST";
export const REMOVE_PIX_CLIENT_SUCCESS = "REMOVE_PIX_CLIENT_SUCCESS";
export const REMOVE_PIX_CLIENT_FAILURE = "REMOVE_PIX_CLIENT_FAILURE";

export const PIX_TRANSACTION_REQUEST = "PIX_TRANSACTION_REQUEST";
export const PIX_TRANSACTION_FAILURE = "PIX_TRANSACTION_FAILURE";
export const PIX_TRANSACTION_SUCCESS = "PIX_TRANSACTION_SUCCESS";

export const PIX_CLIENT_TRANSACTION_REQUEST = "PIX_CLIENT_TRANSACTION_REQUEST";
export const PIX_CLIENT_TRANSACTION_FAILURE = "PIX_CLIENT_TRANSACTION_FAILURE";
export const PIX_CLIENT_TRANSACTION_SUCCESS = "PIX_CLIENT_TRANSACTION_SUCCESS";

export const PIX_CLIENT_REJECT_TRANSACTION_REQUEST =
	"PIX_CLIENT_REJECT_TRANSACTION_REQUEST";
export const PIX_CLIENT_REJECT_TRANSACTION_FAILURE =
	"PIX_CLIENT_REJECT_TRANSACTION_FAILURE";
export const PIX_CLIENT_REJECT_TRANSACTION_SUCCESS =
	"PIX_CLIENT_REJECT_TRANSACTION_SUCCESS";

export const PIX_CLIENT_FEES_TRANSACTION_REQUEST =
	"PIX_CLIENT_FEES_TRANSACTION_REQUEST";
export const PIX_CLIENT_FEES_TRANSACTION_FAILURE =
	"PIX_CLIENT_FEES_TRANSACTION_FAILURE";
export const PIX_CLIENT_FEES_TRANSACTION_SUCCESS =
	"PIX_CLIENT_FEES_TRANSACTION_SUCCESS";

export const PIX_CLIENT_VERIFICATION_CODE_REQUEST =
	"PIX_CLIENT_VERIFICATION_CODE_REQUEST";
export const PIX_CLIENT_VERIFICATION_CODE_FAILURE =
	"PIX_CLIENT_VERIFICATION_CODE_FAILURE";
export const PIX_CLIENT_VERIFICATION_CODE_SUCCESS =
	"PIX_CLIENT_VERIFICATION_CODE_SUCCESS";

export const PIX_CLIENT_APPROVE_TRANSACTION_REQUEST =
	"PIX_CLIENT_APPROVE_TRANSACTION_REQUEST";
export const PIX_CLIENT_APPROVE_TRANSACTION_FAILURE =
	"PIX_CLIENT_APPROVE_TRANSACTION_FAILURE";
export const PIX_CLIENT_APPROVE_TRANSACTION_SUCCESS =
	"PIX_CLIENT_APPROVE_TRANSACTION_SUCCESS";
export const CLEAR_PIX_CLIENT_FEES_TRANSACTION =
	"CLEAR_PIX_CLIENT_FEES_TRANSACTION";

export const PIX_SETTING_REQUEST = "PIX_SETTING_REQUEST";
export const PIX_SETTING_FAILURE = "PIX_SETTING_FAILURE";
export const PIX_SETTING_SUCCESS = "PIX_SETTING_SUCCESS";

export const UPDATE_PIX_SETTING_REQUEST = "UPDATE_PIX_SETTING_REQUEST";
export const UPDATE_PIX_SETTING_FAILURE = "UPDATE_PIX_SETTING_FAILURE";
export const UPDATE_PIX_SETTING_SUCCESS = "UPDATE_PIX_SETTING_SUCCESS";

export const GET_PIX_EXCHANGE_RATE_REQUEST = "GET_PIX_EXCHANGE_RATE_REQUEST";
export const GET_PIX_EXCHANGE_RATE_FAILURE = "GET_PIX_EXCHANGE_RATE_FAILURE";
export const GET_PIX_EXCHANGE_RATE_SUCCESS = "GET_PIX_EXCHANGE_RATE_SUCCESS";

