import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import auth from "./auth/reducer";
import banner from "./banner/reducer";
import admin from "./admin/reducer";
import pix from "./pix/reducer";
import coinList from "./coinList/reducer";
import newCoinList from "./newCoinList/reducer";
import mobileMenu from "./mobileMenu/reducer";
import tkxEndpoint from "./tkxEndpoint/reducer";
import dashboard from "./dashboard/reducer";

const rootReducer = combineReducers({
	Layout,
	auth,
	banner,
	admin,
	pix,
	coinList,
	newCoinList,
	mobileMenu,
	tkxEndpoint,
	dashboard,
});

export default rootReducer;
