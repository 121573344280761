export const ERRORS = "ERRORS";
export const CLEAR_RESPONSE = "CLEAR_RESPONSE";

export const CREATE_COIN_LIST_REQUEST = "CREATE_COIN_LIST_REQUEST";
export const CREATE_COIN_LIST_SUCCESS = "CREATE_COIN_LIST_SUCCESS";
export const CREATE_COIN_LIST_FAILURE = "CREATE_COIN_LIST_FAILURE";

export const UPDATE_COIN_LIST_REQUEST = "UPDATE_COIN_LIST_REQUEST";
export const UPDATE_COIN_LIST_SUCCESS = "UPDATE_COIN_LIST_SUCCESS";
export const UPDATE_COIN_LIST_FAILURE = "UPDATE_COIN_LIST_FAILURE";

export const REMOVE_COIN_LIST_REQUEST = "REMOVE_COIN_LIST_REQUEST";
export const REMOVE_COIN_LIST_SUCCESS = "REMOVE_COIN_LIST_SUCCESS";
export const REMOVE_COIN_LIST_FAILURE = "REMOVE_COIN_LIST_FAILURE";

export const TOGGLE_COIN_LIST_REQUEST = "TOGGLE_COIN_LIST_REQUEST";
export const TOGGLE_COIN_LIST_SUCCESS = "TOGGLE_COIN_LIST_SUCCESS";
export const TOGGLE_COIN_LIST_FAILURE = "TOGGLE_COIN_LIST_FAILURE";
